// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Button } from 'components/Buttons/Button';
import Typography from 'components/Typography/Typography';
import { theme } from 'theme';

export const InfoHeadline = styled(Typography)`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-right: 24px;
  color: ${theme.palette.primary.main};
`;

export const CollapseButton = styled(Button)`
  margin-left: auto;
  background: ${theme.palette.common.white};
  &:hover,
  &:focus {
    background: ${theme.palette.common.white};
    box-shadow: none;
  }
`;

export const DataWrapper = styled(Box)`
  padding-bottom: 16px;
  border-top: 1px solid ${theme.palette.grey['50']};
`;

export const FilesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 24px;
  background-color: ${theme.palette.secondary.main};
`;
