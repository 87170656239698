import { SplitEventProperties } from '@mb/lib/splitio';

export const mergeSplitProperties = (
  baseProperties: SplitEventProperties,
  newProperties?: SplitEventProperties,
) => {
  if (!newProperties) {
    // check if there is session storage available
    const rawSessionValues = sessionStorage.getItem('a1LocationTracking');
    let sessionValues = { properties: {} };

    if (rawSessionValues) {
      sessionValues = JSON.parse(rawSessionValues);
    }

    return { ...baseProperties, ...sessionValues?.properties };
  }
  return { ...baseProperties, ...newProperties };
};
