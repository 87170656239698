import React, { Fragment } from 'react';
import { SplitEventData } from '@mb/lib/splitio';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { VirtuosoGrid } from 'react-virtuoso';
import * as Styled from './FinishesGroup.styled';
import { definedTranslation } from '../../i18n';
import {
  currentColorwayIdSelector,
  selectedChildMaterialSelector,
} from '../../redux/selectors/materialsSelector';
import { FinishesGroupData } from '../../types/material';
import { CardWrapperProps, CardWrapper } from '../Card/CardWrapper';

interface FinishesGroupProps
  extends Omit<CardWrapperProps, 'cardIndex' | 'groupFinishes'> {
  item: FinishesGroupData;
  groupIndex: number;
  activeGroups: number[];
  onActiveGroupChange?: (value: number) => void;
  parentRef: HTMLElement | undefined;
  showAll?: boolean;
  isColorways?: boolean;
  trackingData?: {
    splitio?: SplitEventData;
    gtm?: unknown;
  };
  collapseLimit?: number;
  loadingCartIds?: string[];
  withConfig?: boolean;
}

const ItemContainer = React.forwardRef((props, ref) => {
  // @ts-expect-error // TODO: fix types
  return <Styled.MaterialCell ref={ref}>{props.children}</Styled.MaterialCell>;
});

ItemContainer.displayName = 'ItemContainer';

const FinishesGroup: React.FC<FinishesGroupProps> = ({
  item,
  groupIndex,
  activeGroups,
  onActiveGroupChange,
  clickedProductGridIndex,
  activeProduct,
  projects,
  currentProjectData,
  intl,
  userLang,
  cartItemsIds,
  wasModalTriggered,
  onProductClick,
  onAddToCart,
  parentRef,
  showAll = false,
  isColorways,
  trackingData,
  collapseLimit = 4,
  loadingCartIds,
  withConfig,
}) => {
  const selectedChildMaterial = useSelector(selectedChildMaterialSelector);
  const selectedColorwayId = useSelector(currentColorwayIdSelector);
  const isActiveGroup = activeGroups?.includes(groupIndex) || showAll;
  const groupFinishes = item?.items || [];
  const virtuosoGroupHeight = groupFinishes.length > 0 ? window.innerHeight : 0;

  const labels = {
    more: intl?.formatMessage(definedTranslation.miniPDP.more),
    collapse: intl?.formatMessage(definedTranslation.miniPDP.collapse),
  };

  if (groupFinishes?.length === 0) {
    return <Fragment />;
  }

  const renderCard = (
    index: number,
    trackingData?: { splitio?: SplitEventData; gtm?: unknown },
  ) => {
    return (
      <CardWrapper
        isColorways={isColorways}
        cardIndex={index}
        groupFinishes={groupFinishes}
        clickedProductGridIndex={clickedProductGridIndex}
        activeProduct={activeProduct}
        projects={projects}
        currentProjectData={currentProjectData}
        intl={intl}
        userLang={userLang}
        cartItemsIds={cartItemsIds}
        wasModalTriggered={wasModalTriggered}
        onProductClick={onProductClick}
        onAddToCart={onAddToCart}
        currentMaterialId={
          selectedChildMaterial?.entityId || selectedColorwayId
        }
        trackingData={trackingData}
        loadingCartIds={loadingCartIds}
      />
    );
  };

  return (
    <Styled.MaterialsGroup key={item.title}>
      <Box width="100%" display="flex" marginBottom="20px">
        <Styled.GridHeadline>{item.title}</Styled.GridHeadline>
        {item?.items?.length > collapseLimit && !showAll && (
          <Styled.CollapseButton
            iconDense
            color="secondary"
            variant="contained"
            size="medium"
            endIcon={isActiveGroup ? 'minus' : 'plus'}
            onClick={() => onActiveGroupChange?.(groupIndex)}
            style={{
              marginTop: withConfig ? '46px' : '0',
            }}
          >
            {isActiveGroup
              ? labels.collapse
              : `${item?.items?.length - collapseLimit} ${labels.more}`}
          </Styled.CollapseButton>
        )}
      </Box>
      <Box minHeight="222px">
        {!isActiveGroup || groupFinishes.length <= collapseLimit ? (
          <Box display="flex" flexWrap="wrap">
            {Array.from({ length: collapseLimit }).map((_, index) => (
              <Styled.MaterialCell key={index}>
                {renderCard(index, trackingData)}
              </Styled.MaterialCell>
            ))}
          </Box>
        ) : (
          <VirtuosoGrid
            useWindowScroll
            customScrollParent={parentRef}
            overscan={300}
            style={{ height: virtuosoGroupHeight }}
            totalCount={isActiveGroup ? groupFinishes.length : collapseLimit}
            components={{
              // @ts-expect-error // TODO: fix types
              Item: ItemContainer,
            }}
            itemContent={(index) => renderCard(index, trackingData)}
          />
        )}
      </Box>
    </Styled.MaterialsGroup>
  );
};

export default FinishesGroup;
