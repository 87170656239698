import { createSlice } from '@reduxjs/toolkit';
import { ProductSearchMaterial } from '../../types/material';

interface StripPayloadProps {
  context: string;
  customer: any;
  diversifyFieldName: string;
  excludeFilters: string[];
  excludeItemIds: string[];
  extraFields?: string[];
  lang: string;
  qty: number;
  recommenderMode: string;
  itemIds: string[];
  [key: string]: any;
}
export interface StripDataProps {
  label: string;
  materials: ProductSearchMaterial[];
  payload: StripPayloadProps;
  loaded?: boolean;
  urlPath: string;
}

interface StripData {
  data: StripDataProps[];
}

export const initialState: StripData = {
  data: [],
};

const recommendSlice = createSlice({
  name: 'recommendSlice',
  initialState,
  reducers: {
    setStripData(state, { payload }) {
      state.data = payload;
    },
  },
});

export const { setStripData } = recommendSlice.actions;
export default recommendSlice.reducer;
