import React, { useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { MiniCard } from './MiniCard';
import * as Styled from './RecommendStripUI.styled';
import { RecommendStripUiProps } from './RecommendStripUI.types';
import {
  usePrevNextButtons,
  Arrow,
  ArrowDirection,
} from './SliderArrows/SliderArrows';
import { ProductSearchMaterial } from '../../pages/ProductSearch/types';

const RecommendStripUI: React.FC<RecommendStripUiProps> = ({
  title,
  materials,
  cardButtonsLabels,
  onAddToCart,
  onCardClick,
  cartLoadingIds = [],
  minHeight = 228,
  marginBottom = 32,
  activeItemId,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 'auto',
  });
  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(0);
    }
  }, [emblaApi, materials]);

  return (
    <Styled.ContainerWrapper marginBottom={marginBottom} minHeight={minHeight}>
      {title != null && (
        <Styled.Title variant="h3" fontWeight="bold">
          {title}
        </Styled.Title>
      )}
      <Styled.RecommendContainer>
        <Arrow
          direction={ArrowDirection.LEFT}
          onClick={onPrevButtonClick}
          show={!prevBtnDisabled}
        />
        <Arrow
          direction={ArrowDirection.RIGHT}
          onClick={onNextButtonClick}
          show={!nextBtnDisabled}
        />
        <Styled.ImagesCarousel ref={emblaRef}>
          <Styled.ImagesContent>
            {materials?.map((product: ProductSearchMaterial) => (
              <MiniCard
                isSelected={activeItemId === String(product?.entityId)}
                key={product?.entityId}
                product={product}
                cardButtonsLabels={cardButtonsLabels}
                onAddToCart={onAddToCart}
                onCardClick={onCardClick}
                isAddToCartLoading={cartLoadingIds?.includes(
                  String(product?.entityId),
                )}
              />
            ))}
          </Styled.ImagesContent>
        </Styled.ImagesCarousel>
      </Styled.RecommendContainer>
    </Styled.ContainerWrapper>
  );
};

export { RecommendStripUI };
