import styled from '@emotion/styled';
import { Button } from 'components/Buttons/Button';
import Typography from 'components/Typography/Typography';
import { theme } from 'theme';

export const MaterialsGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  .virtuoso-grid-list {
    margin: 0;
    width: 100%;
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
  }
  .card-boards-button-wrapper {
    top: 6px !important;
    right: 6px !important;
  }
`;

export const GridHeadline = styled(Typography)`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-right: 24px;
  color: ${theme.palette.primary.main};
`;

export const CollapseButton = styled(Button)`
  margin-left: auto;
  background: ${theme.palette.secondary.main};
  &:hover {
    background: ${theme.palette.secondary.main};
    box-shadow: none;
  }
`;

export const MaterialCell = styled.div`
  padding: 5px;
  width: 25%;
`;
