import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  MutableRefObject,
} from 'react';
import {
  AnalyticsEventLocation,
  getAnalyticsItemCategoriesByTaxonomy,
} from '@mb/lib';
import { Box } from '@mui/system';
import uniq from 'lodash/uniq';
import { IntlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import * as Styled from './ImagesContainer.styled';
import { formatImageSource } from '../../../../pages/ProductSearch/helpers';
import { definedTranslation } from '../../../../pages/ProductSearch/i18n';
import { sendAnalytics } from '../../api/events';
import { handleAddToBoards } from '../../helpers';
import { pushItemImageSelectedDataLayer } from '../../helpers/analytics';
import { sanitizeFileName } from '../../helpers/sanitizeFileName';
import { selectedChildMaterialSelector } from '../../redux/selectors/materialsSelector';
import { ProductDetails } from '../../types/product';
import { DownloadButtonComponent } from '../DownloadButtonComponent';
import ImageSlider from '../ImageSlider/ImageSlider';
import TooltipCard from '../TooltipCard/TooltipCard';

interface ImagesContainerProps {
  mainImage: string;
  images: string[];
  activeProduct: ProductDetails;
  itemListId: string;
  itemListName: string;
  clickedProductGridIndex?: number;
  onCloseTooltip: () => void;
  intl: IntlShape;
  shouldHideTooltip?: boolean;
  modalType:
    | AnalyticsEventLocation.MINI_PDP
    | AnalyticsEventLocation.QUICK_VIEW;
  dataLayerAnalytics?: {
    listId: string;
    listName: string;
    eventLocation: string;
  };
}

const ImagesContainer = React.forwardRef<HTMLDivElement, ImagesContainerProps>(
  (
    {
      mainImage,
      images = [],
      activeProduct,
      itemListId,
      itemListName,
      clickedProductGridIndex,
      onCloseTooltip,
      intl,
      shouldHideTooltip,
      modalType,
      dataLayerAnalytics,
    },
    ref,
  ) => {
    const selectedMaterial = useSelector(selectedChildMaterialSelector);
    const [currentImage, setCurrentImage] = useState(mainImage);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const parentRef = useRef<HTMLDivElement | null>(null);
    const stickyRef = useRef<HTMLDivElement | null>(null);
    const [position, setPosition] = useState<'static' | 'absolute' | 'fixed'>(
      'static',
    );
    const [offsetTop, setOffsetTop] = useState(60);
    const localRef = useRef<HTMLDivElement>(null);
    const resolvedRef =
      (ref as MutableRefObject<HTMLDivElement | null>) || localRef;

    const labels = {
      addToBoardLabel: intl?.formatMessage(
        definedTranslation.card.saveButtonLabel,
      ),
    };

    useEffect(() => {
      const parentElement = resolvedRef?.current;
      parentElement?.addEventListener('scroll', handleScroll);

      return () => {
        parentElement?.removeEventListener('scroll', handleScroll);
      };
    }, []);

    useEffect(() => {
      setCurrentImage(mainImage);
    }, [mainImage]);

    const imageList = useMemo(() => {
      const imagesRaw = [mainImage, ...(images || [])].map(
        (item) => item?.split('?')?.[0],
      );
      return uniq(imagesRaw);
    }, [mainImage, images]);

    const handleScroll = () => {
      if (parentRef.current && stickyRef.current) {
        const parentRect = parentRef.current.getBoundingClientRect();
        const childRect = stickyRef.current.getBoundingClientRect();
        if (parentRect.top > 10) {
          setPosition('static');
        } else if (parentRect.bottom > parentRect.height) {
          setOffsetTop(60);
        } else if (parentRect.bottom <= childRect.height) {
          setPosition('absolute');
          setOffsetTop(0);
        } else {
          setPosition('fixed');
          setOffsetTop(32);
        }
      }
    };

    const handleFullScreen = () => {
      pushItemImageSelectedDataLayer([
        {
          item_id: activeProduct?.originalMaterial?.entityId,
          item_name: activeProduct?.originalMaterial?.name,
          item_brand: activeProduct?.originalMaterial?.manufacturer,
          ...getAnalyticsItemCategoriesByTaxonomy(
            activeProduct?.originalMaterial?.taxonomy,
          ),
          index: 1,
          price: 0,
          quantity: 1,
          item_list_id: 'quick_view',
          item_list_name: 'Quick View',
          item_variant: activeProduct?.originalMaterial?.name,
        },
      ]);
      setIsFullScreen((prev) => !prev);
    };

    const handleFavoritesClick = async (event: React.SyntheticEvent) => {
      const product = activeProduct?.originalMaterial;

      event.preventDefault();
      handleAddToBoards(
        product,
        dataLayerAnalytics?.listId ?? itemListId,
        dataLayerAnalytics?.listName ?? itemListName,
        clickedProductGridIndex,
        dataLayerAnalytics?.eventLocation ?? modalType,
      );

      window.splitFactory
        ?.client()
        ?.track('user', 'mb_qv_add_to_board', undefined, {});

      const stringIds = [String(product?.entityId)];
      const value = { value: 'minipdp' };
      await sendAnalytics({ id: stringIds, type: 'favorite', ...value });
    };

    const handleCurrentImage = (image: string, isLast: boolean) => {
      pushItemImageSelectedDataLayer([
        {
          item_id: activeProduct?.originalMaterial?.entityId,
          item_name: activeProduct?.originalMaterial?.name,
          item_brand: activeProduct?.originalMaterial?.manufacturer,
          ...getAnalyticsItemCategoriesByTaxonomy(
            activeProduct?.originalMaterial?.taxonomy,
          ),
          index: 1,
          price: 0,
          quantity: 1,
          item_list_id: 'quick_view',
          item_list_name: 'Quick View',
          item_variant: activeProduct?.originalMaterial?.name,
        },
      ]);
      if (image === currentImage) {
        return;
      }
      const biggerImage = formatImageSource(image, 1200, false, 80);
      setCurrentImage(biggerImage.src);

      if (isLast) {
        window.splitFactory
          ?.client()
          ?.track('user', 'mb_qv_install_image', undefined, {});
      }
    };

    const isFixed = position === 'fixed';
    const isStatic = position === 'static';
    const widthObj = parentRef?.current?.offsetWidth
      ? { width: parentRef?.current?.offsetWidth }
      : {};
    const downloadFileName = `${sanitizeFileName(
      activeProduct?.originalMaterial?.name,
    )}${
      activeProduct?.originalMaterial?.title
        ? `--${sanitizeFileName(activeProduct?.originalMaterial?.title)}`
        : ''
    }`;

    return (
      <Styled.ImageOuterWrapper isFullScreen={isFullScreen} ref={parentRef}>
        <Box sx={{ maxWidth: '94px', position: 'absolute', right: 0 }}>
          {isFullScreen && (
            <Styled.CloseFullScreenButton
              dense
              iconName="cross"
              color="secondary"
              onClick={handleFullScreen}
            />
          )}
          {isFullScreen ? (
            <DownloadButtonComponent
              imageUrl={currentImage}
              productName={downloadFileName}
              iconType="secondary"
            />
          ) : null}
        </Box>
        <Styled.StickyBlock
          ref={stickyRef}
          style={
            !isFullScreen
              ? {
                  position: position,
                  top: isFixed ? 0 : 'auto',
                  bottom: !isFixed ? 0 : 'auto',
                  marginTop: `${isStatic ? 0 : offsetTop}px`,
                  ...widthObj,
                }
              : undefined
          }
        >
          {selectedMaterial != null && !shouldHideTooltip ? (
            <TooltipCard
              material={selectedMaterial}
              onCloseTooltip={onCloseTooltip}
              intl={intl}
            />
          ) : (
            <Styled.ImagesColumn>
              {!isFullScreen && (
                <Styled.AddToBoardButton
                  color="secondary"
                  size="large"
                  variant="contained"
                  onClick={handleFavoritesClick}
                >
                  {labels.addToBoardLabel}
                </Styled.AddToBoardButton>
              )}
              <Styled.ImageWrapper>
                <Box position="relative" maxHeight="100%">
                  {!isFullScreen && (
                    <Styled.FullScreenButton
                      dense
                      iconName="full-screen"
                      color="secondary"
                      onClick={handleFullScreen}
                    />
                  )}
                  {!isFullScreen ? (
                    <DownloadButtonComponent
                      imageUrl={currentImage}
                      productName={downloadFileName}
                    />
                  ) : null}
                  <Styled.ActiveImage
                    src={currentImage}
                    onClick={handleFullScreen}
                  />
                </Box>
              </Styled.ImageWrapper>
              <ImageSlider
                imageList={imageList}
                currentImage={currentImage}
                onImageSelect={handleCurrentImage}
                activeProduct={activeProduct}
              />
            </Styled.ImagesColumn>
          )}
        </Styled.StickyBlock>
      </Styled.ImageOuterWrapper>
    );
  },
);

export default ImagesContainer;
