import React, { useCallback, useMemo } from 'react';
import { AnalyticsEventLocation } from '@mb/lib';
import { ProjectDropdownVariant } from 'components/Cards/ProductCard/ProductCard.types';
import ProjectDropdown from 'components/Cards/ProductCard/ProjectDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProjects } from '../../api/projects';
import { handleProjectDropdownClick } from '../../helpers';
import useCurrentProject from '../../hooks/useCurrentProject';
import useSwitchCurrentUserProject from '../../hooks/useSwitchCurrentUserProject';
import {
  projectsListSelector,
  projectTypesListSelector,
} from '../../redux/selectors/projectsSelector';
import {
  setCreatedProject,
  setCurrentProjectId,
  setIsProjectModalOpen,
  setProjectModalEventLocation,
} from '../../redux/sliceCreators/projectsSlice';
import { IProject, StructuredProject } from '../../types/projects';

interface ProjectMenuDropdownProps {
  entityId: string;
  createProjectButtonLabel: string;
}

const ProjectMenuDropdown: React.FC<ProjectMenuDropdownProps> = ({
  entityId,
  createProjectButtonLabel,
}) => {
  const dispatch = useDispatch();
  const projects = useSelector(projectsListSelector);
  const projectTypes = useSelector(projectTypesListSelector);
  const { currentProject } = useCurrentProject();
  const { setCurrentUserProject } = useSwitchCurrentUserProject();

  const structuredProjects = useMemo(() => {
    return [...projects].map((project) => ({
      id: project.project_id,
      label: project.project_title,
    }));
  }, [projects]);

  const currentProjectData = useMemo(
    () => ({
      id: currentProject?.project_id || 0,
      label: currentProject?.project_title ?? 'Select project..',
    }),
    [currentProject],
  ) as StructuredProject;

  const onCreateProjectClick = useCallback(
    (params?: { analyticsEventLocation?: string }) => {
      const { analyticsEventLocation } = params ?? {};

      dispatch(setIsProjectModalOpen(true));

      if (analyticsEventLocation) {
        dispatch(setProjectModalEventLocation(analyticsEventLocation));
      }
    },
    [],
  );

  const onProjectSelect = useCallback((projectId: number) => {
    dispatch(setCurrentProjectId(projectId));
  }, []);

  const onProjectCreate = useCallback((project: IProject) => {
    dispatch(setCreatedProject(project));
  }, []);

  const handleProjectClick = (projectId: string) => {
    const project = projects.find(
      (project: IProject) => project.project_id === projectId,
    );

    const onLocalCurrentProjectSave = (project: IProject) => {
      onProjectSelect(project.project_id as number);
      dispatch(getUserProjects());
    };

    if (project) {
      handleProjectDropdownClick(
        project,
        projectTypes,
        AnalyticsEventLocation.QUICK_VIEW,
        onLocalCurrentProjectSave,
        onProjectCreate,
        // @ts-expect-error // TODO: fix types
        setCurrentUserProject,
      );
    }
  };

  return (
    <ProjectDropdown
      shouldShow
      entityId={entityId}
      maxWidth={300}
      isContained={false}
      // @ts-expect-error // TODO: fix types
      onProjectClick={handleProjectClick}
      onCreateProject={onCreateProjectClick}
      selectedProject={currentProjectData}
      projects={structuredProjects}
      variant={ProjectDropdownVariant.GHOST}
      preLabel={''}
      createProjectButtonLabel={createProjectButtonLabel}
      hideAddToBag
      minWidth={120}
    />
  );
};

export { ProjectMenuDropdown };
