import moment from 'moment';
import { createDeepEqualSelector } from './';

export const projectsSelector = (state: any) => state.projects;

moment().utc().format('YYYY-MM-DD HH:mm:ss');

export const projectsListSelector = createDeepEqualSelector(
  projectsSelector,
  (projects) =>
    projects.projectsList
      // @ts-expect-error // TODO: fix types
      ?.filter((project) => !project.is_archived)
      ?.sort(
        // @ts-expect-error // TODO: fix types
        (a, b) =>
          // @ts-expect-error // TODO: fix types
          moment(a.updated_at).format('YYYY-MM-DD HH:mm:ss') -
          // @ts-expect-error // TODO: fix types
          moment(b.updated_at).format('YYYY-MM-DD HH:mm:ss'),
      ) || [],
);

export const projectTypesListSelector = createDeepEqualSelector(
  projectsSelector,
  (projects) => projects.projectTypes || [],
);

export const currentProjectIdSelector = createDeepEqualSelector(
  projectsSelector,
  (projects) => {
    return projects.currentProjectId;
  },
);

export const projectPhasesSelector = createDeepEqualSelector(
  projectsSelector,
  (projects) => projects.phases,
);

export const isProjectModalOpenSelector = createDeepEqualSelector(
  projectsSelector,
  (projects) => projects.isProjectModalOpen,
);

export const isProjectsLoadedSelector = createDeepEqualSelector(
  projectsSelector,
  (projects) => projects.isProjectsLoaded,
);

export const projectModalEventLocationSelector = createDeepEqualSelector(
  projectsSelector,
  (projects) => projects.projectModalEventLocation,
);
