import React, { Fragment, useState } from 'react';
import { Box } from '@mui/system';
import { useSelector, useDispatch } from 'react-redux';
import * as Styled from './RecommendBlock.styled';
import { RecommendStripUI } from '../../../RecommendStripUI';
import { getRecommendStrip } from '../../api/materials';
import useAddToBagWithValidations from '../../hooks/useAddToBagWithValidations';
import { recommendDataSelector } from '../../redux/selectors/recommendSelector';
import {
  setStripData,
  StripDataProps,
} from '../../redux/sliceCreators/recommendSlice';
import { ProductSearchMaterial } from '../../types/material';

interface RecommendBlockProps {
  activeItemId?: string;
  onRecommendationClick: (product: ProductSearchMaterial) => void;
}

const RecommendBlock: React.FC<RecommendBlockProps> = ({
  onRecommendationClick,
  activeItemId,
}) => {
  const materialsData = useSelector(recommendDataSelector);
  const [activeTab, setActiveTab] = useState(0);
  const { handleAddToBag: addToBag, loadingIds } = useAddToBagWithValidations();
  const dispatch = useDispatch();

  const handleTabChange = (_: any, value: number) => {
    const selectedTabData = materialsData?.[value];
    if (selectedTabData) {
      if (selectedTabData?.materials?.length === 0 && !selectedTabData.loaded) {
        getNewTabContent(selectedTabData);
      }
    }
    setActiveTab(value);
  };

  const getNewTabContent = async (selectedTabData: StripDataProps) => {
    const loadedMaterials = await getRecommendStrip({
      urlPath: selectedTabData.urlPath,
      payload: selectedTabData.payload,
    });
    const newRecommendData = materialsData?.map((item: StripDataProps) => {
      if (item.label === selectedTabData.label) {
        return { ...item, materials: loadedMaterials, loaded: true };
      } else {
        return { ...item, loaded: true };
      }
    });
    dispatch(setStripData(newRecommendData));
  };

  const handleAddToCart = (product: ProductSearchMaterial) => {
    addToBag({
      product,
      shouldTriggerRecModal: false,
      trackingData: {},
      onAddToCartClick: undefined,
      qvLocation: product?.qvLocation || 'recommend',
      atcLocation: 'QV',
      gaEventLocation: `quick view recos ab b ${product?.analyticsPostfix}`,
      dataLayerAnalytics: {
        listId: `qvrecos_ab_b_${product?.analyticsPostfix}`,
        listName: `quick view recos ab b ${product?.analyticsPostfix}`,
        eventLocation: `quick view recos ab b ${product?.analyticsPostfix}`,
      },
    });
  };

  const currentTabData = materialsData?.[activeTab];

  const handleFilterEmptyList = (item: StripDataProps) => {
    return item?.materials?.length > 0 || !item.loaded;
  };

  if (
    currentTabData == null ||
    (currentTabData?.materials?.length === 0 && currentTabData?.loaded)
  ) {
    return <Fragment />;
  }

  return (
    <Box
      id="recommendBlockMiniPDP"
      display="flex"
      width="calc(100% - 8px)"
      mt="0"
      flexDirection="column"
      position="relative"
      left="4px"
    >
      <Styled.CategoriesTabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {materialsData
          ?.filter(handleFilterEmptyList)
          // @ts-expect-error // TODO: fix types
          ?.map(({ label }, index: number) => (
            <Styled.CategoryTab
              disableRipple
              key={label}
              label={label}
              value={index}
            />
          ))}
      </Styled.CategoriesTabs>
      <RecommendStripUI
        cartLoadingIds={loadingIds}
        materials={currentTabData?.materials}
        // @ts-expect-error // TODO: fix types
        onAddToCart={handleAddToCart}
        // @ts-expect-error // TODO: fix types
        onCardClick={onRecommendationClick}
        minHeight={140}
        marginBottom={0}
        activeItemId={activeItemId}
      />
    </Box>
  );
};

export { RecommendBlock };
