import React, { FC } from 'react';

type AllItemsAddedToCartSnackbarProps = {
  totalAmountOfAddedItems: number;
};

const AllItemsAddedToCartSnackbar: FC<AllItemsAddedToCartSnackbarProps> = ({
  totalAmountOfAddedItems,
}) => <>{`${totalAmountOfAddedItems} Items Added to Cart`}</>;

export default AllItemsAddedToCartSnackbar;
