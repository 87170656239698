import React from 'react';
import * as Styled from './BackButton.styled';

interface BackButtonProps {
  onButtonClick: () => void;
  name: string;
}

const BackButton: React.FC<BackButtonProps> = ({ onButtonClick, name }) => {
  return (
    <Styled.GoBackButton
      variant="body"
      component="button"
      onClick={() => onButtonClick?.()}
    >
      <Styled.BackIcon name="back" />
      <span className="text">Back to {name}</span>
    </Styled.GoBackButton>
  );
};

export { BackButton };
