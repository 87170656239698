import { MutableRefObject } from 'react';
import { SplitEventData } from '@mb/lib/splitio';
import { IntlShape } from 'react-intl';
import { ProductSearchMaterial } from './material';
import { IProject, StructuredProject } from './projects';

export interface DataModalProps {
  onPdpClose: (event: any, reason?: string) => void;
  itemListId?: string;
  itemListName?: string;
  clickedProductGridIndex?: number;
  clickedProductRequestId?: string;
  cartItemsIds?: string[];
  intl: IntlShape;
  projects?: IProject[];
  currentProjectData: StructuredProject;
  shouldShowContactButton: boolean;
  structuredProjects: StructuredProject[];
  isAbEnabled?: boolean;
  userLang: 'en' | 'es' | 'de' | 'fr' | 'it';
  trackingData?: {
    splitio?: SplitEventData;
    gtm?: unknown;
  };
  learnMoreNewTab?: boolean;
  wasModalTriggered: boolean;
  onModalTriggered: (value: boolean) => void;
  onContactRepClick: (ref: MutableRefObject<HTMLDivElement | null>) => void;
  dataLayerAnalytics?: {
    listId: string;
    listName: string;
    eventLocation: string;
  };
  onVariationClick?: (material: ProductSearchMaterial) => void;
}

export enum ResponseStatusEnum {
  FAILED = 'failed',
  SUCCESS = 'success',
}

export enum SnackbarStatusEnum {
  DANGER = 'danger',
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
}
