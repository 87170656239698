// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Button } from 'components/Buttons/Button';
import { IconButton } from 'components/Buttons/IconButton';
import TypographyComponent from 'components/Typography/Typography';
import palette from 'theme/palette';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${palette.common.white};
  border-radius: 12px;
  box-shadow: 4px 4px 25px
    ${(props) => props.theme.hexToRGBA(palette.common.black, 0.08)};
  width: 90%;
  margin: 0 auto;
`;

export const CloseIcon = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 9;
  width: 48px;
  height: 48px;
  background: #fff;
  color: ${palette.primary.main};
  &:hover {
    background: #fff;
    color: ${palette.primary.main};
    box-shadow: none;
  }
`;

export const ImageWrapper = styled.div<{ imageUrl: string }>`
  width: 100%;
  background-color: ${palette.grey[200]};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: ${(props) =>
    props.imageUrl != null
      ? `
    url('${props.imageUrl}');
    `
      : 'none'};
  height: 316px;
  border-radius: 12px 12px 0 0;
`;

export const InfoWrapper = styled.div`
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
`;

export const Category = styled(TypographyComponent)`
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: ${palette.grey['A400']};
`;
export const Brand = styled(TypographyComponent)`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${palette.primary.main};
`;
export const Sku = styled(TypographyComponent)`
  color: ${palette.primary.main};
  font-size: 13px;
  line-height: 20px;
`;
export const Title = styled(TypographyComponent)`
  margin-bottom: 0;
  color: ${palette.primary.main};
  font-size: 16px;
  line-height: 28px;
  margin-top: 6px;
`;

export const LearnMoreButton = styled(Button)`
  margin-left: auto;
  width: 160px;
  background: ${palette.secondary.main};
  &:hover {
    background: ${palette.secondary.main};
    box-shadow: none;
  }
`;

export const Line = styled(Box)`
  display: flex;
  font-size: 13px;
  line-height: 20px;
`;
export const Label = styled(TypographyComponent)`
  margin-right: 8px;
  color: ${palette.grey['A400']};
`;
export const Value = styled(TypographyComponent)`
  color: ${palette.primary.main};
`;
