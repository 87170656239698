import { buildSplitAddToCartEventData } from '@mb/lib/splitio';
import xor from 'lodash/xor';
import { useMediaQuery } from 'react-responsive';
import { useAPICallStateHandler } from './customHooks';
import useCurrentProject from './useCurrentProject';
import {
  getIsTierAllowed as getIsRecommendationModalTierAllowed,
  getRecommendationsModalSettings,
} from '../../../../../../Recommendation/view/frontend/app/api/localStorageCalls';
import { getAnalyticsDataIfCollection } from '../../../pages/ProductSearch/utils/helpers';
import { sendAnalytics } from '../api/events';
import { client } from '../api/http';
import { endpoints } from '../config';
import { getUserAttributes, redirectGuestToLogin } from '../helpers';
import { getIsPaintProductType } from '../helpers';
import {
  pushGtmDataLayer,
  pushSearchAnalyticsDataLayer,
} from '../helpers/analytics';
import { projectsListSelector } from '../redux/selectors/projectsSelector';
import {
  addCartItemsInQueue,
  setSnackbarInfo,
  SnackbarInfo,
  setMaterialsInQueue,
} from '../redux/sliceCreators/commonSlice';
import { setIsProjectModalOpen } from '../redux/sliceCreators/projectsSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { ResponseStatusEnum, SnackbarStatusEnum } from '../types';
import { ProductSearchMaterial } from '../types/material';
import { IProject } from '../types/projects';

const isRMDataInLocalStorage = Boolean(getRecommendationsModalSettings());

declare let MEDIA_URL: string;

function formatControlRulesImageUrl(strUrl: string | null | undefined) {
  if (strUrl) {
    return strUrl.startsWith('//') ? `https:${strUrl}` : strUrl;
  } else {
    return null;
  }
}

export const fetchServices = async (productId: string) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('product_id[0]', productId);
    const { data } = await client.post(endpoints.getServices, bodyFormData);
    const response = data.response ? data : null;

    return response;
  } catch (error) {
    console.log('Error fetchServices >>> ', error.message);

    return null;
  }
};

export const getConfigurableProduct = async (productSku: string) => {
  const userData = getUserAttributes();
  try {
    const response = await fetch(
      `${endpoints.getConfigurableProduct}/${productSku}`,
      {
        headers: {
          Authorization: `Bearer ${userData?.signin_token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.json();
  } catch (e) {
    console.log('error getConfigurableProduct', e);
    return null;
  }
};

interface RelatedProduct {
  title: string;
  id: string | number;
}

const useAddToBag = () => {
  const dispatch = useAppDispatch();
  const projectsList = useAppSelector(projectsListSelector);
  const { state, setState } = useAPICallStateHandler();
  const { currentProject } = useCurrentProject();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isRMtierAllowed = getIsRecommendationModalTierAllowed();

  const addToBag = async (
    materials: ProductSearchMaterial[],
    projectId: string | number | null,
    project?: IProject,
    relatedProductData?: RelatedProduct,
    triggerLocation = 'Catalog',
    isFinishMaterial: boolean = false,
    shouldTriggerModal: boolean = true,
    customSuccessMessage?: Pick<SnackbarInfo, 'messagePayload' | 'messageType'>,
    onSuccess?: () => void,
    additionalSplitIoProps: Record<string, any> = {},
    dataLayerAnalytics?: {
      listId: string;
      listName: string;
      eventLocation: string;
    },
  ) => {
    try {
      redirectGuestToLogin();
      const ids = materials?.map((item) => String(item.entityId));
      if (projectsList?.length === 0 && project == null) {
        if (Array.isArray(materials)) {
          dispatch(setMaterialsInQueue(materials));
        } else {
          dispatch(setMaterialsInQueue([materials]));
        }
        dispatch(setIsProjectModalOpen(true));
        return;
      }

      setState((prev) => ({
        isLoading: true,
        loadingIds: [...(prev.loadingIds || []), ...ids],
      }));

      const params = new URLSearchParams();
      const bagProject =
        project ??
        projectsList.find((item: IProject) => item?.project_id === projectId) ??
        currentProject ??
        projectsList?.[0];
      if (bagProject == null) {
        throw new Error('Please select a project');
      }
      const splitObj = {
        sku: materials?.map((item) => item.sku).join(','),
        'ATC-location': 'QV',
      };

      if (window.location.pathname.includes('collections/')) {
        // @ts-expect-error // TODO: fix types
        splitObj['ATC-collections'] =
          materials.length > 1 ? 'ATC-multiselect' : 'ATC-individual';
      }
      const { eventType, value, properties } =
        buildSplitAddToCartEventData(splitObj);
      window.splitFactory?.client()?.track('user', eventType, value, {
        ...properties,
        ...additionalSplitIoProps,
      });

      pushGtmDataLayer({
        event: 'add-to-cart',
        value: {
          entityIds: materials?.map((item) => item.entityId),
        },
      });

      const currentMaterial =
        materials.find((item) => !item.isInRealTimeStock) || materials?.[0];
      const isPaint =
        currentMaterial?.isPaint || getIsPaintProductType(currentMaterial);
      const materialSku = materials
        .map((material) => String(material.colorwayId || material.entityId))
        ?.splice(0, 1);

      if (!isPaint && shouldTriggerModal) {
        window.dispatchEvent(
          new CustomEvent('open-recommendations-modal', {
            detail: {
              inProgress: true,
              shouldShowProposal: true,
              skus: materialSku,
              isInRealTimeStock: currentMaterial?.isInRealTimeStock ?? false,
              materialId: String(currentMaterial?.entityId),
              bagProject,
              addedMaterialImage: currentMaterial?.thumbnailImageUrl ?? null,
            },
          }),
        );
      }

      let itemListId = dataLayerAnalytics?.listId ?? 'mini_pdp_samples';
      let itemListName = dataLayerAnalytics?.listName ?? 'Mini PDP';

      if (window.location.pathname?.includes('/curatedcollections/')) {
        const { id, title } = getAnalyticsDataIfCollection();
        itemListId = id;
        itemListName = title;
      }

      materials.forEach((item, index) => {
        params.append(`product_id[${index}]`, item.entityId);
        params.append(`qty[${index}]`, '1');
        params.append(
          `project_name[${index}]`,
          bagProject?.title || bagProject?.project_title,
        );
        params.append(
          `project_type[${index}]`,
          bagProject?.type?.externalId?.toString() || bagProject?.project_type,
        );
        params.append(
          `project_id[${index}]`,
          bagProject?.externalId || bagProject?.project_id,
        );
        params.append(`item_list_id[${index}]`, itemListId);
        params.append(`item_list_name[${index}]`, itemListName);
        params.append(`index[${index}]`, String(item?.gridIndex || index + 1));

        if (relatedProductData?.title != null) {
          params.append(`related_product[${index}]`, relatedProductData.title);
        }
        if (relatedProductData?.id != null) {
          params.append(
            `related_product_id[${index}]`,
            String(relatedProductData.id),
          );
        }
      });
      const { data } = await client.post(endpoints.addToBag, params);
      const services = await fetchServices(currentMaterial.entityId);
      if (data?.status === ResponseStatusEnum.FAILED) {
        dispatch(
          setSnackbarInfo({
            isOpen: true,
            message: data?.message ?? 'Some error occurred',
            status: SnackbarStatusEnum.DANGER,
          }),
        );

        window.dispatchEvent(new CustomEvent('close-recommendations-modal'));
      } else {
        const isServerMessage = data?.message?.length > 0;
        const cartItemsLength = data?.total_qty ?? 0;
        const addedMaterialsLength =
          materials.length >= cartItemsLength
            ? cartItemsLength
            : cartItemsLength - materials.length;
        const snackbarMessage = isServerMessage
          ? 'Items added to cart'
          : `${materials.length} items added to cart`;
        const isInStock =
          currentMaterial?.isInRealTimeStock ||
          (currentMaterial?.originalMaterial?.qty || 0) > 0;

        // If at least 1 material was added to cart
        if (addedMaterialsLength > 0 && !isServerMessage) {
          if (!isPaint && shouldTriggerModal) {
            if (isInStock) {
              if (services != null) {
                window.dispatchEvent(
                  new CustomEvent('open-services-modal', {
                    detail: {
                      inProgress: false,
                      shouldShowProposal: true,
                      serverMessage: null,
                      skus: materialSku,
                      isInRealTimeStock: true,
                      materialId: String(currentMaterial?.entityId),
                      bagProject,
                      addedMaterialImage:
                        currentMaterial?.thumbnailImageUrl ?? null,
                      currentMaterial,
                      service: services,
                    },
                  }),
                );
              }
            } else {
              window.dispatchEvent(
                new CustomEvent('open-recommendations-modal', {
                  detail: {
                    inProgress: false,
                    shouldShowProposal: true,
                    serverMessage: null,
                    skus: materialSku,
                    isInRealTimeStock:
                      currentMaterial?.isInRealTimeStock ?? false,
                    materialId: String(currentMaterial?.entityId),
                    bagProject,
                    addedMaterialImage:
                      currentMaterial?.thumbnailImageUrl ?? null,
                    currentMaterial,
                  },
                }),
              );
            }
          }
        } else if (isServerMessage) {
          const productThumbnail =
            data?.productThumbnail != null
              ? `${MEDIA_URL}catalog/product${data?.productThumbnail}`
              : null;
          const errorMaterialImage =
            productThumbnail ||
            formatControlRulesImageUrl(data?.modal_data?.[0]?.image_url) ||
            currentMaterial?.thumbnailImageUrl;
          const controlRuleId = data?.modal_data?.[0]?.control_rule_id ?? null;

          window.dispatchEvent(
            new CustomEvent('open-recommendations-modal', {
              detail: {
                forceAllow: true,
                inProgress: false,
                shouldShowProposal: false,
                serverMessage: data?.message,
                skus: materialSku,
                isInRealTimeStock: false,
                materialId: String(currentMaterial?.entityId),
                bagProject,
                addedMaterialImage: errorMaterialImage ?? null,
                controlRuleId,
              },
            }),
          );
        }

        // If more than 1 material was added to cart
        if (
          addedMaterialsLength > 0 &&
          !(isServerMessage && materials.length === 1)
        ) {
          const isOutOfStock = !currentMaterial?.isInRealTimeStock;
          const itemHasServices =
            currentMaterial?.isInRealTimeStock && services != null;
          const rmWillShow =
            !isMobile &&
            isRMtierAllowed &&
            isRMDataInLocalStorage &&
            !isPaint &&
            shouldTriggerModal &&
            (isOutOfStock || itemHasServices);

          if (!rmWillShow || materials.length > 1) {
            if (!isServerMessage) {
              if (onSuccess && typeof onSuccess === 'function') {
                onSuccess();

                if (customSuccessMessage) {
                  dispatch(
                    setSnackbarInfo({
                      isOpen: true,
                      message: undefined,
                      status: SnackbarStatusEnum.DEFAULT,
                      ...customSuccessMessage,
                    }),
                  );
                }
              } else {
                dispatch(
                  setSnackbarInfo({
                    isOpen: true,
                    status: SnackbarStatusEnum.DEFAULT,
                    message:
                      materials.length > 1 ? snackbarMessage : 'Added to cart',
                  }),
                );
              }
            }
          }

          setTimeout(() => {
            window.dispatchEvent(
              new CustomEvent('reload-customer-section-data'),
            );
          }, 500);

          const idsList = materials.map((item) => String(item.entityId));
          const cartItems = materials.map((item) => {
            return {
              entityId: item.entityId,
              time: Date.now(),
            };
          });
          dispatch(addCartItemsInQueue(cartItems));
          sendAnalytics({
            id: idsList,
            type: 'cart',
            customRequestId: materials?.[0]?.originRequestId,
            customClass: materials?.[0]?.analyticsClass,
            impressions: materials?.[0]?.siblingIds,
          });

          // Analytics
          pushSearchAnalyticsDataLayer(
            materials,
            '',
            dataLayerAnalytics?.listId,
            dataLayerAnalytics?.listName,
            'add_to_cart',
            dataLayerAnalytics?.eventLocation ?? triggerLocation,
          );
        }
      }
      dispatch(setMaterialsInQueue([]));

      setState((prev) => ({
        isLoading: false,
        loadingIds: xor(prev.loadingIds, ids),
      }));
      window.dispatchEvent(new CustomEvent('trigger-ga-data'));
    } catch (error) {
      console.error('Cannot add to bag', error);
      if (error.message) {
        window.dispatchEvent(new CustomEvent('close-recommendations-modal'));

        dispatch(
          setSnackbarInfo({
            isOpen: true,
            status: SnackbarStatusEnum.DANGER,
            message: error.message ?? 'Some error occurred',
          }),
        );
      }
      setState({ isLoading: false, error, loadingIds: [] });
      window.dispatchEvent(new CustomEvent('trigger-ga-data'));
    }
  };

  return { ...state, addToBag };
};

export default useAddToBag;
