import React, { useCallback, FC } from 'react';
import { ReactComponent as DownloadIcon } from './download-icon.svg';
import style from './DownloadButtonComponent.module.scss';

interface DownloadButtonComponentProps {
  imageUrl: string;
  productName?: string;
  iconType?: 'secondary' | 'primary';
}

export const DownloadButtonComponent: FC<DownloadButtonComponentProps> = ({
  imageUrl,
  productName,
  iconType = 'primary',
}) => {
  const downloadImageUrl = `${imageUrl.split('?')[0]}?format=jpg`;

  const triggerDownload = useCallback(
    async (fileUrl: string, filename: string) => {
      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading the file', error);
      }
    },
    [],
  );

  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        triggerDownload(downloadImageUrl, productName || 'image.jpg');
      }}
      download="image.jpg"
      className={`${style.downloadButton}${
        iconType === 'secondary' ? ` ${style['downloadButton--secondary']}` : ''
      }`}
    >
      <DownloadIcon />
    </a>
  );
};
