export const definedTranslation = {
  miniPDP: {
    detailsButtonLabel: {
      id: 'catalog-mini-pdp-learn-more-button',
      defaultMessage: 'Learn more',
    },
    contactRepButtonLabel: {
      id: 'catalog-mini-pdp-contact-rep-button',
      defaultMessage: 'Contact rep',
    },
    infoBrandLabel: {
      id: 'catalog-mini-pdp-brand-label',
      defaultMessage: 'Brand',
    },
    infoUsageLabel: {
      id: 'catalog-mini-pdp-usage-label',
      defaultMessage: 'Usage',
    },
    infoSkuLabel: {
      id: 'catalog-mini-pdp-sku-label',
      defaultMessage: 'SKU',
    },
    indoorOutdoorLabel: {
      id: 'catalog-mini-pdp-indoor-outdoor-label',
      defaultMessage: 'Indoor / Outdoor',
    },
    sizeLabel: {
      id: 'catalog-mini-pdp-size-label',
      defaultMessage: 'Size',
    },
    noteLabel: {
      id: 'catalog-mini-pdp-note-label',
      defaultMessage: 'Note',
    },
    createProjectButtonLabel: {
      id: 'catalog-create-project-button',
      defaultMessage: 'Create Project',
    },
    filesHeadline: {
      id: 'catalog-mini-pdp-files-headline',
      defaultMessage: 'Files',
    },
    more: {
      id: 'catalog-mini-pdp-more-button',
      defaultMessage: 'more',
    },
    collapse: {
      id: 'catalog-mini-pdp-collapse-button',
      defaultMessage: 'Collapse',
    },
    download: {
      id: 'catalog-mini-pdp-download-button',
      defaultMessage: 'Download',
    },
  },
  quickView: {
    variationsLabel: {
      id: 'catalog-mini-pdp-variations-label',
      defaultMessage: 'Variations',
    },
    detailsButtonLabel: {
      id: 'catalog-mini-pdp-learn-more-button',
      defaultMessage: 'Learn more',
    },
    projectPreLabel: {
      id: 'catalog-project-selector-label',
      defaultMessage: 'Project',
    },
    addToCartButtonLabel: {
      id: 'catalog-material-add-to-cart-button',
      defaultMessage: 'Add to Cart',
    },
    createProjectButtonLabel: {
      id: 'catalog-create-project-button',
      defaultMessage: 'Create Project',
    },
    contactRepButtonLabel: {
      id: 'catalog-contact-rep-button',
      defaultMessage: 'Contact Rep',
    },
  },
  projectMessages: {
    projectUpdated: {
      id: 'catalog-project-updated-message',
      defaultMessage: 'Current project successfully updated',
    },
    projectAdded: {
      id: 'catalog-project-added-message',
      defaultMessage: 'Project Successfully Added',
    },
    errorProjectSet: {
      id: 'catalog-error-project-set',
      defaultMessage: 'Cannot set current project',
    },
  },
};
