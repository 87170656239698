import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
  MutableRefObject,
} from 'react';
import { AnalyticsEventLocation } from '@mb/lib';
import { isEuEnv } from '@mb/lib/i18n';
import { encodeFilterValue } from '@mb/lib/search';
import { Box } from '@mui/system';
import { AttachmentFile } from 'components/components/FilesList';
import indexOf from 'lodash/indexOf';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import xor from 'lodash/xor';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './MiniPdpContent.styled';
import { formatImageSource } from '../../../../pages/ProductSearch/helpers';
import { sendAnalytics } from '../../api/events';
import { getMaterialsByKey } from '../../api/materials';
import { AddToBagButton } from '../../components/AddToBagButton';
import { AttachmentsList } from '../../components/AttachmentsList';
import DescriptionContainer from '../../components/DescriptionContainer/DescriptionContainer';
import { FinishesConfig } from '../../components/FinishesConfig';
import FinishesGroup from '../../components/FinishesGroup/FinishesGroup';
import ImagesContainer from '../../components/ImagesContainer/ImagesContainer';
import { ProjectMenuDropdown } from '../../components/ProjectMenuDropdown/ProjectMenuDropdown';
import { checkStockAvailability, getActiveProductData } from '../../helpers';
import { transformMaterialToAppFormat } from '../../helpers';
import {
  buildAnalyticsParamsToPDP,
  handleMiniPdpDetailsClickDataLayer,
  handleMiniPdpItemImageSelectedDataLayer,
  handleProductViewedEvent,
  pushSearchAnalyticsDataLayer,
} from '../../helpers/analytics';
import useAddToBagWithValidations from '../../hooks/useAddToBagWithValidations';
import { definedTranslation } from '../../i18n';
import { currentMaterialSelector } from '../../redux/selectors/materialsSelector';
import {
  setSelectedChildMaterial,
  setCurrentColorwayId,
} from '../../redux/sliceCreators/materialsSlice';
import { DataModalProps } from '../../types';
import { ProductSearchMaterial } from '../../types/material';
import { ProductDetails } from '../../types/product';
import { CurrentConfigData, SelectboxConfig } from '../../types/quickView';

const QuickViewContent: React.FC<DataModalProps> = React.forwardRef(
  (
    {
      onPdpClose,
      itemListId = 'quick_view',
      itemListName = 'Quick View',
      clickedProductGridIndex,
      cartItemsIds = [],
      intl,
      structuredProjects = [],
      shouldShowContactButton,
      userLang,
      currentProjectData,
      trackingData = {},
      learnMoreNewTab = false,
      wasModalTriggered = false,
      onModalTriggered,
      onContactRepClick,
      dataLayerAnalytics,
      onVariationClick,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const [activeProduct, setActiveProduct] = useState<ProductDetails | null>(
      null,
    );
    const [attachments, setAttachments] = useState<AttachmentFile[]>([]);
    const [installImages, setInstallImages] = useState<string[]>([]);
    const portalTargetRef = useRef<HTMLDivElement | null>(null);
    const currentMaterial = useSelector(currentMaterialSelector);
    const localRef = useRef<HTMLDivElement>(null);
    const resolvedRef =
      (ref as MutableRefObject<HTMLDivElement | null>) || localRef;

    const [colorways, setColorways] = useState<any[]>([]);
    const [configData, setConfigData] = useState<SelectboxConfig[]>([]);
    const [activeGroups, setActiveGroups] = useState<number[]>([]);
    const [currentConfig, setCurrentConfig] = useState<CurrentConfigData[]>([]);
    const { handleAddToBag: addToBag, loadingIds } =
      useAddToBagWithValidations();
    const isAddToCartLoading = loadingIds?.includes(
      String(activeProduct?.entityId),
    );

    const urlPrefix = isEuEnv ? `/${userLang}` : '';

    const translation = {
      detailsButtonLabel: intl?.formatMessage(
        definedTranslation.miniPDP.detailsButtonLabel,
      ),
      createProject: intl?.formatMessage(
        definedTranslation.miniPDP.createProjectButtonLabel,
      ),
      contactRepLabel: intl?.formatMessage(
        definedTranslation.miniPDP.contactRepButtonLabel,
      ),
    };

    const localLabels = {
      variations: intl?.formatMessage(
        definedTranslation.quickView.variationsLabel,
      ),
      addToCartLabel: intl?.formatMessage(
        definedTranslation.quickView.addToCartButtonLabel,
      ),
    };

    const shouldShowPDP = activeProduct != null;

    useEffect(() => {
      initProduct(currentMaterial);
    }, [currentMaterial]);

    useEffect(() => {
      if (activeProduct !== null) {
        handleProductViewedEvent(activeProduct?.originalMaterial);
      }
    }, [activeProduct]);

    const getColorwaysData = async (product: ProductSearchMaterial | null) => {
      if (product == null) return;
      let facetColorways = product?.ssFacetColorFilter || [];
      let defaultConfigurations = product?.configurations || [];
      const isPaint = product.isPaint;
      let newCurrectChild: string | null = String(product.entityId);
      const isParent = String(product.familyId) === String(product.entityId);

      // Try get Colorways from parent group material (in case if Display All toggled)
      if (product.familyId != null && !isParent) {
        const { results: mainMaterialResults } = await getMaterialsByKey([
          product.familyId as string,
        ]);
        const currentMaterialDetails = mainMaterialResults?.[0];
        if (Array.isArray(currentMaterialDetails?.ss_facet_color_filter)) {
          facetColorways = currentMaterialDetails?.ss_facet_color_filter;
          defaultConfigurations = currentMaterialDetails?.configurations;
        } else {
          return;
        }
      }

      // If no Variations data - return
      if (defaultConfigurations?.length === 0 && facetColorways?.length === 0) {
        return;
      }

      const configurations = defaultConfigurations?.map((config) => {
        const orderedItems = orderBy(config?.items, ['entity_id'], ['asc']);
        return {
          ...config,
          items: orderedItems,
        };
      });

      // Map data for Configuration/Settings selectboxes
      const selectboxesData =
        configurations?.map(({ attribute, variants, label }) => ({
          attribute,
          variants,
          label,
        })) || [];

      setConfigData(selectboxesData);

      const formatedResults = facetColorways?.map((item) => ({
        entityId: item.entity_id,
        imageUrl: item.image_url,
        manufacturerSku: item.manufacturer_sku,
        title: item.exact_color || product?.name,
      }));

      const sortedItems = orderBy(formatedResults || [], ['position'], ['asc']);
      const itemsIds = sortedItems?.map((item) => String(item.entityId));
      // Get all data related to items, because ssFacetColorFilter is limited in info
      const { results } = await getMaterialsByKey(itemsIds);
      const formatedResultsWithData = results.map(transformMaterialToAppFormat);
      const primaryChild = formatedResultsWithData.find(
        (item: ProductSearchMaterial) => item.isPrimary,
      );
      if (primaryChild != null && isParent) {
        newCurrectChild = String(primaryChild.entityId);
      }
      const combinedList = formatedResultsWithData?.map(
        (item: ProductSearchMaterial) => {
          const color = formatedResults?.find(
            (colorItem) => String(colorItem.entityId) === String(item.entityId),
          );
          if (color != null) {
            return {
              ...item,
              ...color,
            };
          }
          return [];
        },
      );
      // Fix sorting, because after byKey request, order is changed
      const sortedList = sortBy([...combinedList], function (obj) {
        const index = indexOf(itemsIds, obj?.entityId);
        return index !== -1 ? index : itemsIds.length;
      });

      // Get all configurations for each material
      const finalColorwayList = sortedList?.map((item, i) => {
        // List of additional params may be different for different items
        const additionalParams: { [key: string]: string } = {};
        configurations?.forEach((item) => {
          if (item.items?.[i]?.label != null) {
            additionalParams[item.attribute] = item.items[i].label as string;
          }
        });

        return {
          ...item,
          configData: additionalParams,
        };
      });

      // Set default config for current product
      let findCurrentColorway = finalColorwayList.find((material) => {
        return newCurrectChild === String(material.entityId);
      });

      // Set correct SKU of current material in Description
      if (!isPaint) {
        setActiveProduct((prev: ProductDetails) => ({
          ...prev,
          sku: findCurrentColorway?.manufacturerSku,
        }));
      }

      if (findCurrentColorway == null) {
        findCurrentColorway = finalColorwayList.find((material) => {
          return product.url === material.url;
        });
      }

      // Set config for Selectboxes, like Size, texture, etc..
      if (
        findCurrentColorway?.configData != null &&
        selectboxesData?.length !== 0
      ) {
        const materialBasedConfigRaw = findCurrentColorway?.configData;
        const materialBasedConfig = Object.keys(materialBasedConfigRaw).map(
          (key) => {
            return {
              attribute: key,
              label: materialBasedConfigRaw[key],
            };
          },
        );
        setCurrentConfig(materialBasedConfig || []);
      } else if (currentConfig?.length === 0 && configData?.length !== 0) {
        const newData = configData.map((item) => ({
          attribute: item.attribute,
          label: item.variants?.[0]?.label,
        }));
        setCurrentConfig(newData);
      }

      dispatch(setCurrentColorwayId(newCurrectChild));
      setColorways(finalColorwayList);
      // Request isInStock after items are showing, to reduce blocking time
      const stockResults = await checkStockAvailability(finalColorwayList);
      // Set default colorway
      const currentChild = stockResults.find(
        (material) => String(material?.entityId) === String(newCurrectChild),
      );
      if (currentChild != null) {
        let childData = currentChild;
        if (currentChild?.url != null) {
          const { url: childUrl, ...rest } = currentChild;
          childData = {
            ...rest,
            url: `${urlPrefix}${childUrl}`,
          };
        }
        handleSetActiveProduct(childData);
      }
      setColorways(stockResults);
    };

    const initProduct = (material: ProductSearchMaterial) => {
      if (material != null) {
        getColorwaysData(material);
        handleSetActiveProduct(material);
        // Show parent additional images without parent main image
        const parentImages = (material?.installImages || []).filter(
          (item: string) => {
            const itemRaw = item.split('?')?.[0];
            const parentImg = material?.thumbnailImageUrl?.split('?')?.[0];
            return itemRaw !== parentImg;
          },
        );
        setInstallImages(parentImages);
      }
    };

    const sendViewItemAnalytics = (product: ProductSearchMaterial) => {
      // Analytics
      pushSearchAnalyticsDataLayer(
        [product],
        '',
        product?.isDigital ? 'digital_sampling' : 'quick_view',
        product?.isDigital ? 'digital sampling' : 'Quick View',
        'view_item',
        product?.isDigital ? 'digital sampling' : 'Quick-View',
      );
    };

    const handleSetActiveProduct = (
      product: ProductSearchMaterial | null,
    ): boolean => {
      if (product == null) return false;

      sendViewItemAnalytics(product);
      const productDetails = getActiveProductData(
        product,
        dataLayerAnalytics?.listId ?? itemListId,
        dataLayerAnalytics?.listName ?? itemListName,
      );
      setActiveProduct(productDetails);
      if (productDetails?.attachments != null) {
        // Hide attachments URL and redirect to pdp
        const newFiles = productDetails?.attachments?.map((item) => ({
          ...item,
          file_url: item.url,
          url: `${productDetails.materialUrl}&fileId=${item.id}`,
        }));
        setAttachments(newFiles);
      }
      return false;
    };

    const handleProductClick = async (
      material: ProductSearchMaterial,
      isFromCalc = false,
    ) => {
      dispatch(setCurrentColorwayId(String(material?.entityId)));
      const productDetails = getActiveProductData(
        material,
        itemListId,
        itemListName,
      );
      if (!isFromCalc) {
        await sendAnalytics(
          {
            id: [String(productDetails?.entityId)],
            type: 'view',
            value: 'minipdp',
          },
          1200,
        );
        if (activeProduct != null) {
          handleMiniPdpItemImageSelectedDataLayer(
            activeProduct?.originalMaterial,
          );
        }
        sendViewItemAnalytics(productDetails?.originalMaterial);
      }
      setActiveProduct(productDetails);

      // Set color filter is config
      setCurrentConfig((prev) => {
        return prev?.map((conf) => {
          if (conf.attribute === 'color') {
            return {
              attribute: conf.attribute,
              label: material?.configData?.color || conf.label,
            };
          } else {
            return conf;
          }
        });
      });
      if (!currentMaterial?.isRecommendation) {
        onVariationClick?.(material);
      }
    };

    const filterByConfig = (materialData: {
      configData: { [x: string]: string };
    }) => {
      if (currentConfig?.length == 0) {
        return true;
      }
      // Do not consider Color filter, because we are showing ALL colorways in grid
      const localConfig = [...currentConfig].filter(
        (conf) => conf.attribute !== 'color',
      );
      return localConfig?.every(
        (item) => materialData?.configData?.[item.attribute] === item.label,
      );
    };

    const handleFilterChange = useCallback(
      (value: string, attribute: string) => {
        setCurrentConfig((prev) => {
          let futureConfig = prev;
          const newConfig = prev?.map((item) => {
            if (item.attribute !== attribute) {
              return item;
            } else {
              return {
                ...item,
                label: value,
              };
            }
          });
          const isMaterialWithNewConfig = colorways?.some((material) => {
            return newConfig?.every(
              (item) => material?.configData?.[item.attribute] === item.label,
            );
          });

          if (isMaterialWithNewConfig) {
            futureConfig = newConfig;
          } else {
            // If there is no material with new config, find material which
            //have newConfig, and material config settings apply as selected new config
            const materialWithSelectedConfig = colorways?.find((item) => {
              return item?.configData?.[attribute] === value;
            });
            if (materialWithSelectedConfig != null) {
              const materialBasedConfigRaw =
                materialWithSelectedConfig?.configData || {};
              const materialBasedConfig = Object.keys(
                materialBasedConfigRaw,
              ).map((key) => {
                return {
                  attribute: key,
                  label: materialBasedConfigRaw[key],
                };
              });
              futureConfig = materialBasedConfig;
            } else {
              futureConfig = newConfig;
            }
          }

          // On new settings select by user - select first material in grid and make it active
          let firstMaterialAfterFilter = colorways.filter((material) => {
            return futureConfig?.every(
              (item) => material?.configData?.[item.attribute] === item.label,
            );
          })?.[0];
          if (firstMaterialAfterFilter == null) {
            // If no material with same color, find material with different color, but with selected
            // settings
            // Do not consider Color filter, because we are showing ALL colorways in grid
            const localConfig = [...futureConfig].filter(
              (conf) => conf.attribute !== 'color',
            );
            firstMaterialAfterFilter = colorways.filter((material) => {
              return localConfig?.every(
                (item) => material?.configData?.[item.attribute] === item.label,
              );
            })?.[0];
          }
          if (firstMaterialAfterFilter != null) {
            const pdpTrackParams: string = buildAnalyticsParamsToPDP({
              product: activeProduct,
              itemListId,
              itemListName,
              index: 1,
            });
            handleProductClick(
              {
                ...firstMaterialAfterFilter,
                url: `${firstMaterialAfterFilter.url}?activeChild=${firstMaterialAfterFilter.entityId}&${pdpTrackParams}`,
              },
              true,
            );
          }
          return futureConfig;
        });
      },
      [colorways],
    );

    const handleDetailsClick = async (e: React.SyntheticEvent) => {
      e.preventDefault();
      await sendAnalytics(
        {
          id: [String(activeProduct?.entityId)],
          type: 'view',
          value: 'minipdp',
        },
        1200,
      );
      handleMiniPdpDetailsClickDataLayer(
        {
          ...activeProduct?.originalMaterial,
          url: activeProduct?.materialUrl || '',
        } as ProductSearchMaterial,
        learnMoreNewTab,
      );
    };

    const handleAddedToCart = (product?: ProductSearchMaterial) => {
      const isProduct = Boolean(product);
      const productData = product || activeProduct?.originalMaterial;
      if (!productData) {
        return;
      }
      addToBag({
        product: productData,
        shouldTriggerRecModal: !wasModalTriggered,
        trackingData,
        onAddToCartClick: undefined,
        qvLocation: isProduct ? 'variations' : 'top',
        atcLocation: 'QV',
        dataLayerAnalytics,
      });
      onModalTriggered?.(true);
    };

    const handleCollapseClick = (index: number) => {
      setActiveGroups((prev) => xor([index], prev));
    };

    const handleCloseTooltip = () => {
      dispatch(setSelectedChildMaterial(undefined));
    };

    const bannerImageUrl = formatImageSource(
      activeProduct?.imageUrl,
      1200,
      false,
      80,
    );
    const currentGroup = {
      title: localLabels.variations,
      items: colorways.filter(filterByConfig),
    };
    const prefix = userLang === 'en' ? '' : `/${userLang}`;
    const productCategory = activeProduct?.originalMaterial?.taxonomy?.[0];
    const brandLink = `${prefix}/search?q=#/filter:manufacturer:${encodeFilterValue(
      activeProduct?.brandLabel || '',
    )}#/page:1`;
    const categoryLink = `${prefix}/search?q=#/filter:taxonomy:${encodeFilterValue(
      productCategory || '',
    )}#/page:1`;
    const hasAttachments =
      Array.isArray(attachments) && attachments?.length > 0;
    const isDigitalProduct = activeProduct?.originalMaterial?.isDigital;

    const handleCategoryClick = (e: React.SyntheticEvent) => {
      e.preventDefault();
      window.location.href = categoryLink;
      onPdpClose?.(undefined, 'redirect');
    };

    const handleBrandClick = (e: React.SyntheticEvent) => {
      e.preventDefault();
      window.location.href = brandLink;
      onPdpClose?.(undefined, 'redirect');
    };

    if (!shouldShowPDP) {
      return <Fragment />;
    }

    const LearnMoreButton = () => (
      <Styled.LearnMoreButton
        LinkComponent={'a'}
        href={activeProduct?.materialUrl}
        size="large"
        variant="contained"
        color="primary"
        onClick={handleDetailsClick}
      >
        {translation.detailsButtonLabel}
      </Styled.LearnMoreButton>
    );

    const CartButton = () => (
      <AddToBagButton
        isAddToCartLoading={isAddToCartLoading}
        onAddToCart={handleAddedToCart}
      />
    );

    const installShotsList = [
      ...(activeProduct?.installImages || []),
      ...(installImages || []),
    ];

    return (
      <Styled.MiniPdpColumnsWrapper>
        <ImagesContainer
          shouldHideTooltip
          activeProduct={activeProduct}
          mainImage={bannerImageUrl?.src}
          images={installShotsList}
          itemListId={itemListId}
          itemListName={itemListName}
          clickedProductGridIndex={clickedProductGridIndex}
          onCloseTooltip={handleCloseTooltip}
          intl={intl}
          modalType={AnalyticsEventLocation.QUICK_VIEW}
          dataLayerAnalytics={dataLayerAnalytics}
          ref={resolvedRef}
        />
        <Styled.InfoOuterWrapper>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Styled.ProductTypeText
              component="a"
              href={categoryLink}
              onClick={handleCategoryClick}
            >
              {activeProduct.type}
            </Styled.ProductTypeText>
            <Styled.MainHeadline>{activeProduct.nameLabel}</Styled.MainHeadline>
            <Styled.MainSubtitle
              component="a"
              href={brandLink}
              onClick={handleBrandClick}
            >
              {activeProduct.brandLabel}
            </Styled.MainSubtitle>
          </Box>
          <Styled.ActionsWrapper>
            <Styled.ContactRepPortal ref={portalTargetRef} />
            {!isDigitalProduct && <LearnMoreButton />}
            {shouldShowContactButton && (
              <Styled.TooltipInfo
                PopperProps={{
                  disablePortal: true,
                }}
                title={translation.contactRepLabel}
              >
                <Styled.ContactRepButton
                  size="large"
                  iconName="mail"
                  color="secondary"
                  onClick={() => onContactRepClick?.(portalTargetRef)}
                />
              </Styled.TooltipInfo>
            )}
            <Styled.ProjectSelector>
              <ProjectMenuDropdown
                entityId={activeProduct?.entityId}
                createProjectButtonLabel={translation.createProject}
              />
            </Styled.ProjectSelector>
            {isDigitalProduct ? <LearnMoreButton /> : <CartButton />}
          </Styled.ActionsWrapper>
          <DescriptionContainer
            intl={intl}
            activeProduct={activeProduct}
            showSize={false}
          />
          {hasAttachments && isDigitalProduct ? (
            <AttachmentsList
              item={activeProduct?.originalMaterial}
              list={attachments}
              productUrl={activeProduct?.materialUrl}
              productSku={activeProduct?.sku}
              intl={intl}
            />
          ) : (
            <Styled.Line />
          )}
          {configData?.length > 0 && (
            <FinishesConfig
              configData={configData}
              currentConfig={currentConfig}
              onFilterChange={handleFilterChange}
            />
          )}
          <Box width="100%" display="flex" flexDirection="column">
            <FinishesGroup
              withConfig={configData?.length > 0}
              collapseLimit={8}
              isColorways
              item={currentGroup}
              groupIndex={0}
              activeGroups={activeGroups}
              onActiveGroupChange={handleCollapseClick}
              parentRef={resolvedRef?.current || undefined}
              intl={intl}
              userLang={userLang}
              clickedProductGridIndex={clickedProductGridIndex}
              activeProduct={activeProduct}
              projects={structuredProjects}
              currentProjectData={currentProjectData}
              cartItemsIds={cartItemsIds}
              wasModalTriggered={wasModalTriggered}
              onProductClick={handleProductClick}
              onAddToCart={handleAddedToCart}
              trackingData={trackingData}
              loadingCartIds={loadingIds}
            />
          </Box>
        </Styled.InfoOuterWrapper>
      </Styled.MiniPdpColumnsWrapper>
    );
  },
);

QuickViewContent.displayName = 'QuickViewContent';

export { QuickViewContent };
