import styled from '@emotion/styled';
import { IconButton } from 'components/Buttons/IconButton';

export const LeftArrow = styled(IconButton)`
  &.Mui-disabled {
    background: transparent;
  }
`;
export const RightArrow = styled(IconButton)`
  &.Mui-disabled {
    background: transparent;
  }
`;
