import { useCallback, useMemo } from 'react';
import {
  shouldTriggerProjectSelectionDialog,
  AnalyticsEventLocation,
} from '@mb/lib';
import { mergeSplitProperties, SplitEventData } from '@mb/lib/splitio';
import { useDispatch, useSelector } from 'react-redux';
import { triggerAssociatedProductsModal } from '../../../pages/ProductSearch/utils/helpers';
import {
  handleTriggerProjectSelectModal,
  redirectGuestToLogin,
} from '../helpers';
import useCurrentProject from './useCurrentProject';
import useAddToBag from '../hooks/useAddToBag';
import { projectsListSelector } from '../redux/selectors/projectsSelector';
import { setMaterialsInQueue } from '../redux/sliceCreators/commonSlice';
import {
  setCreatedProject,
  setCurrentProjectId,
  setIsProjectModalOpen,
  setProjectModalEventLocation,
} from '../redux/sliceCreators/projectsSlice';
import { ProductSearchMaterial } from '../types/material';
import { IProject, StructuredProject } from '../types/projects';

interface SplitData {
  splitio?: SplitEventData;
  gtm?: unknown;
}

interface AddToCartProps {
  product: ProductSearchMaterial;
  shouldTriggerRecModal: boolean;
  onAddToCartClick?: () => void;
  trackingData?: SplitData;
  gaEventLocation?: string;
  qvLocation: string;
  atcLocation: string;
  dataLayerAnalytics?: {
    listId: string;
    listName: string;
    eventLocation: string;
  };
  relatedProductData?: {
    id: string;
    title: string;
  };
  isFinish?: boolean;
}

const useAddToBagWithValidations = () => {
  const { isLoading, loadingIds, addToBag } = useAddToBag();
  const { currentProject } = useCurrentProject();
  const projects = useSelector(projectsListSelector);
  const dispatch = useDispatch();

  const selectedProject = useMemo(
    () => ({
      id: currentProject?.project_id || 0,
      label: currentProject?.project_title ?? 'Select project..',
    }),
    [currentProject],
  ) as StructuredProject;

  const onProjectSelect = useCallback((projectId: number | string) => {
    dispatch(setCurrentProjectId(projectId));
  }, []);

  const onProjectCreate = useCallback((project: IProject) => {
    dispatch(setCreatedProject(project));
  }, []);

  const onCreateProjectClick = useCallback(
    (params?: { analyticsEventLocation?: string }) => {
      const { analyticsEventLocation } = params ?? {};

      dispatch(setIsProjectModalOpen(true));

      if (analyticsEventLocation) {
        dispatch(setProjectModalEventLocation(analyticsEventLocation));
      }
    },
    [],
  );

  const handleAddToBag = (props: AddToCartProps) => {
    const {
      product,
      onAddToCartClick,
      shouldTriggerRecModal = false,
      gaEventLocation = 'Quick View',
      qvLocation = 'top',
      atcLocation = 'QV',
      trackingData = {},
      dataLayerAnalytics,
      isFinish,
      relatedProductData,
    } = props;
    redirectGuestToLogin();

    const analyticsEventData = dataLayerAnalytics
      ? {
          item_list_id: dataLayerAnalytics.listId,
          item_list_name: dataLayerAnalytics.listName,
          event_location: dataLayerAnalytics.eventLocation,
        }
      : undefined;

    if ((product?.associatedFinishProducts || []).length > 0 && !isFinish) {
      // If Finishes - trigger modal to select associated Products
      const productsIds = product?.associatedFinishProducts?.join('|');

      if (shouldTriggerProjectSelectionDialog()) {
        const onLocalProjectSave = (project: IProject) => {
          onProjectSelect?.(project.project_id);
          triggerAssociatedProductsModal(
            productsIds,
            {
              id: product.entityId,
              name: product.name,
              manufacturerName: product.manufacturer,
              productImageUrl: product.imageUrl,
            },
            analyticsEventData,
          );
        };
        const onLocalProjectCreate = (project: IProject) => {
          onProjectCreate?.(project);
        };
        handleTriggerProjectSelectModal(
          selectedProject?.id,
          AnalyticsEventLocation.QUICK_VIEW,
          onLocalProjectSave,
          onLocalProjectCreate,
        );
        return;
      }

      triggerAssociatedProductsModal(
        productsIds,
        {
          id: product?.entityId,
          name: product?.name,
          manufacturerName: product?.manufacturer,
          productImageUrl: product?.imageUrl,
        },
        analyticsEventData,
      );
      return;
    }
    if (projects?.length > 0 && Boolean(selectedProject.id)) {
      if (shouldTriggerProjectSelectionDialog()) {
        const onLocalProjectSave = (project: IProject) => {
          onProjectSelect?.(project.project_id);
          onAddToCartClick?.();
          addToBag(
            [product],
            project.project_id,
            project,
            relatedProductData,
            gaEventLocation,
            true,
            shouldTriggerRecModal,
            undefined,
            undefined,
            mergeSplitProperties(
              {
                qvLocation: qvLocation,
                'ATC-location': atcLocation,
              },
              trackingData?.splitio?.properties,
            ),
            dataLayerAnalytics,
          );
        };
        const onLocalProjectCreate = (project: IProject) => {
          onProjectCreate?.(project);
        };

        handleTriggerProjectSelectModal(
          selectedProject?.id,
          gaEventLocation,
          onLocalProjectSave,
          onLocalProjectCreate,
        );
        return;
      }
      onAddToCartClick?.();
      addToBag(
        [product],
        selectedProject.id,
        undefined,
        relatedProductData,
        gaEventLocation,
        true,
        shouldTriggerRecModal,
        undefined,
        undefined,
        mergeSplitProperties(
          {
            qvLocation: qvLocation,
            'ATC-location': atcLocation,
          },
          trackingData?.splitio?.properties,
        ),
        dataLayerAnalytics,
      );
    } else {
      onCreateProjectClick?.();
      dispatch(setMaterialsInQueue([product]));
    }
  };

  return { isLoading, loadingIds, handleAddToBag };
};

export default useAddToBagWithValidations;
