import React, { useRef, useMemo } from 'react';
import { isCustomerAllowedToContactRep } from '@mb/lib/customer';
import { getLangLinkPrefix } from '@mb/lib/i18n';
import { ProductCard } from 'components/Cards/ProductCard';
import { ProductCardActionType } from 'components/Cards/ProductCard/ProductCard.types';
import isEqual from 'lodash/isEqual';

import { formatImageSource } from '../../../../pages/ProductSearch/helpers';
import { definedTranslation } from '../../../../pages/ProductSearch/i18n';
import { sendAnalytics } from '../../api/events';
import { handleAddToBoards } from '../../helpers';
import {
  buildAnalyticsParamsToPDP,
  mapMaterialsForAnalytics,
  pushLearnMoreCtaClickDataLayer,
} from '../../helpers/analytics';

const langPrefix = getLangLinkPrefix();
interface ssColor {
  color_name: string;
  entity_id: string | number;
  exact_color: string;
  image_url: string;
  manufacturer_sku: string;
  sku: string;
}

// TODO: describe types
export interface ResultCardProps extends Record<string, any> {}

const ResultCard = React.memo<ResultCardProps>((props) => {
  const {
    material: product,
    imageSize = 252,
    selectedProject,
    onProductClick,
    onCreateProjectClick,
    projects,
    isSelected,
    cartItemsIds = [],
    isColorway = false,
    isMiniPDPFinish = false,
    isMobile,
    isHoverAllowed,
    isOutlined,
    relatedProductInfo = null,
    onContextMenuClick,
    onAddToCart,
    shouldTriggerRecModal = true,
    itemListId = 'mini_pdp',
    itemListName = 'Mini PDP',
    analyticsEventLocation,
    index,
    onProjectSelect,
    onProjectCreate,
    additionalSplitIoProps,
    intl,
    loadingCartIds,
  } = props;

  const {
    name,
    entityId,
    manufacturer,
    manufacturerSku,
    ssFacetColorFilter,
    blurhash,
    isInRealTimeStock,
    isNew,
    isJustAdded,
    colorwayId,
    isCollection,
    associatedFinishProducts = [],
    isDigital,
  } = product;

  const selectedSSFacetColor = useRef<ssColor | null>(null);
  const isInCart = cartItemsIds.includes(entityId);
  const itemId = Number(selectedSSFacetColor?.current?.entity_id) || entityId;
  const isProduct = product?.productFinishType?.includes('Product');
  const isFinishMaterial =
    product?.productFinishType?.includes('Finish') || isMiniPDPFinish;
  const showContactRepButton = !isProduct && isCustomerAllowedToContactRep();
  const isAddToCartLoading = loadingCartIds?.includes(String(itemId));
  const cardLabel = isColorway ? product?.title : name;

  const cardLabels = {
    addToCartLabel: intl?.formatMessage(definedTranslation.card.addToCartLabel),
    addedToCartLabel: intl?.formatMessage(
      definedTranslation.card.addToCartLabel,
    ),
    createProjectButtonLabel: intl?.formatMessage(
      definedTranslation.card.createProjectButtonLabel,
    ),
    inStockLabel: intl?.formatMessage(definedTranslation.card.inStockLabel),
    learnMoreLabel: intl?.formatMessage(definedTranslation.card.learnMoreLabel),
    outOfStockLabel: intl?.formatMessage(
      definedTranslation.card.outOfStockLabel,
    ),
    projectPreLabel: intl?.formatMessage(
      definedTranslation.card.projectPreLabel,
    ),
    saveButtonLabel: intl?.formatMessage(
      definedTranslation.card.saveButtonLabel,
    ),
    savedButtonLabel: intl?.formatMessage(
      definedTranslation.card.savedButtonLabel,
    ),
    savingButtonLabel: intl?.formatMessage(
      definedTranslation.card.savingButtonLabel,
    ),
    searchPlaceholderLabel: intl?.formatMessage(
      definedTranslation.filters.searchPlaceholder,
    ),
  };

  const internalLabels = {
    orderNow: intl?.formatMessage(definedTranslation.card.orderNow),
    sampleFinishesLabel: intl?.formatMessage(
      definedTranslation.card.sampleFinishesLabel,
    ),
  };

  const imageUrl = useMemo(() => {
    const thumbnail = product.thumbnailImageUrl;

    // image from API comes with get param ?width=X, we need to replace that
    // to adjust size to column size
    const defaultSizeImage = thumbnail?.split('?')?.[0];
    const finalImageSize = Math.max(600, imageSize);
    const finalUrl = defaultSizeImage
      ? formatImageSource(defaultSizeImage, finalImageSize, false)
      : null;
    return finalUrl?.src || null;
  }, [product, imageSize]);

  let materialUrl: string;
  const pdpTrackParams: string = buildAnalyticsParamsToPDP({
    product,
    itemListId,
    itemListName,
    index: index !== undefined ? index + 1 : undefined,
  });

  if (colorwayId != null) {
    materialUrl = `${product.url}?activeChild=${colorwayId}&${pdpTrackParams}`;
  } else if (selectedSSFacetColor?.current != null) {
    materialUrl = `${product.url}?activeChild=${selectedSSFacetColor?.current?.entity_id}&${pdpTrackParams}`;
  } else if (isFinishMaterial || !isCollection) {
    materialUrl = `${product.url}?activeChild=${entityId}&${pdpTrackParams}`;
  } else {
    materialUrl = `${product.url}?${pdpTrackParams}`;
  }

  const handleFavoritesClick = async () => {
    const currentItemId = product?.id != null ? product.id : product.entityId;
    handleAddToBoards(
      product,
      itemListId,
      itemListName,
      product.gridIndex,
      analyticsEventLocation,
    );

    window.splitFactory
      ?.client()
      ?.track('user', 'mb_qv_add_to_board', undefined, {});

    const stringIds = [String(currentItemId)];
    const value = { value: 'minipdp' };
    await sendAnalytics({ id: stringIds, type: 'favorite', ...value });
  };

  const handleAddToBoxClick = () => {
    const currentProduct = {
      ...product,
      entityId: selectedSSFacetColor?.current?.entity_id || product.entityId,
    };
    onAddToCart?.(currentProduct, isFinishMaterial);
  };

  const handleRedirect = (id?: string) => {
    const { gaType } = mapMaterialsForAnalytics(
      [product],
      cardLabel,
      'digital_sampling',
      'digital sampling',
    );
    pushLearnMoreCtaClickDataLayer(gaType, selectedProject);

    const newUrl =
      id != null && colorwayId == null
        ? `${product.url}?activeChild=${id}`
        : materialUrl;
    window.location.href = `${langPrefix}${newUrl}`;
    return false;
  };

  const handleProductClick = (id?: string) => {
    const newUrl =
      id != null && colorwayId == null
        ? `${product.url}?activeChild=${id}`
        : materialUrl;
    onProductClick?.({
      ...product,
      url: newUrl,
      manufacturerSku:
        selectedSSFacetColor?.current?.manufacturer_sku || manufacturerSku,
      thumbnailImageUrl: imageUrl || product.thumbnailImageUrl || '',
      entityId: id || itemId,
      isColorApplied: Boolean(selectedSSFacetColor?.current?.manufacturer_sku),
      index,
    });
  };

  const handleRightClick = (id: string | number) => {
    onContextMenuClick?.({ ...product, entityId: id || itemId });
  };

  const isNewLabel = isNew
    ? [{ label: 'New' }]
    : isJustAdded
    ? [{ label: 'Just Added' }]
    : [];
  const blurhashString = blurhash?.length >= 6 ? blurhash : null;
  const finalCardUrl = isProduct
    ? materialUrl?.split('?activeChild')?.[0]
    : materialUrl;
  const actionType: ProductCardActionType =
    isProduct || isDigital ? 'customAction' : 'addToCart';

  return (
    <ProductCard
      showOnHoverOnlyOvernight
      isCompactDescription
      showProjectsDropdown
      showBag
      tooltipTitle={cardLabel}
      subtitle2={cardLabel}
      entityId={entityId}
      imageUrl={imageUrl!}
      projects={projects}
      productUrl={finalCardUrl}
      topInfoItems={isNewLabel}
      isOvernight={isInRealTimeStock}
      isMobileDevice={isMobile}
      showAdvancedInfo
      showSelectOnHover={false}
      isHoverAllowed={isHoverAllowed}
      isOutlined={isOutlined}
      isSelected={isSelected}
      showHoverFeatures={false}
      isAddedToFavorites={false}
      afterLabel={undefined}
      blurhash={blurhashString}
      showContainedInfo
      showExtraInfo={false}
      actionType={actionType}
      actionButtonText={
        isDigital
          ? cardLabels.learnMoreLabel
          : internalLabels.sampleFinishesLabel
      }
      // @ts-expect-error // TODO: fix types
      onClickAddToFavorites={isMobile ? null : handleFavoritesClick}
      selectedProject={selectedProject}
      onAddToBag={handleAddToBoxClick}
      isAddToCartLoading={isAddToCartLoading}
      isAddedToCart={isInCart}
      onProductClick={handleProductClick}
      onActionButtonClick={isDigital ? handleRedirect : handleProductClick}
      // @ts-expect-error // TODO: fix types
      onRightClick={handleRightClick}
      showContactRepButton={showContactRepButton}
      isDigital={isDigital}
      {...cardLabels}
    />
  );
}, isEqual);

ResultCard.displayName = 'ResultCard';

export { ResultCard };
