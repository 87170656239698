export function encodeFilterValue(value: string) {
  return value
    ?.replace(/(\s)/g, '$2520')
    ?.replace(/(\&)/g, '$2526')
    ?.replace(/(\>)/g, '$253E')
    ?.replace(/(\/)/g, '$252F');
}

// buildSearchLink returns a search page href from given raw unencoded parts
// parts ["filter:taxonomy:Materials>Textile", "filter:application_filter:Furniture"]
// -> /search?q=#/filter:taxonomy:Materials$253ETextile#/filter:application_filter:Furniture
export function buildSearchLink(parts: string[]) {
  return `/search?q=${parts
    .filter(Boolean)
    .map((part) => `#/${encodeFilterValue(part)}`)
    .join('')}`;
}
