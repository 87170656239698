import React, { FC } from 'react';
import MBDialog from 'components/Elements/MBDialog';
import type { MBDialogProps } from 'components/Elements/MBDialog/MBDialog.types';
import Typography from 'components/Typography/Typography';
import { useDispatch, useSelector } from 'react-redux';

import { errorMessageSelector } from '../../redux/selectors/commonSelector';
import { setErrorMessage } from '../../redux/sliceCreators/commonSlice';

interface ErrorModalProps extends Partial<MBDialogProps> {
  dialogWidth?: number;
}

const ErrorModal: FC<ErrorModalProps> = ({ dialogWidth = 400 }) => {
  const { body, showCancelButton, shouldDisableBottomPadding, title } =
    useSelector(errorMessageSelector);

  const dispatch = useDispatch();

  const handleClearErrorMessage = () => {
    dispatch(setErrorMessage({ body: '', title: '' }));
  };

  return (
    <MBDialog
      cancelButtonLabel="Close"
      dialogWidth={dialogWidth}
      id="notification_dialog_popup"
      onClose={handleClearErrorMessage}
      open={body != null && body !== ''}
      showCloseIcon
      title={title}
      {...(showCancelButton && {
        onCancelButtonClick: handleClearErrorMessage,
      })}
      {...(shouldDisableBottomPadding && {
        shouldDisableBottomPadding,
      })}
    >
      <Typography dangerouslySetInnerHTML={{ __html: body }} variant="body" />
    </MBDialog>
  );
};

export default ErrorModal;
