// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';

export const ImagesGridWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export const ImagesContent = styled.div<{ isAligned?: boolean }>`
  display: flex;
  gap: 8px;
  ${({ isAligned }) => isAligned && 'justify-content: center;'}
`;

export const ImagesCarousel = styled.div`
  overflow: hidden;
  width: 356px;
`;

export const Image = styled.img<{ isSelected: boolean }>`
  width: 64px;
  height: 64px;
  border-radius: 12px;
  filter: brightness(0.95);
  cursor: pointer;
  object-fit: cover;
  ${({ isSelected, theme }) =>
    isSelected &&
    `
        padding: 2px;
        border: 2px solid ${theme.palette.primary.main};
    `}
`;
