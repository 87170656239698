// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';
import { Tabs, Tab } from '@mui/material';

export const CategoriesTabs = styled(Tabs)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[50]};
  margin-bottom: 1em;
  margin-top: 0.5em;
  & .MuiTab-root {
    padding-inline: 0;
    margin-right: 0.6em;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-transform: none;
    color: ${({ theme }) => theme.palette.grey['A400']};
    &.Mui-selected {
      color: ${({ theme }) => theme.palette.primary.main} !important;
    }
  }
  & .MuiTabScrollButton-root.Mui-disabled {
    opacity: 0.25 !important;
  }
`;

export const CategoryTab = styled(Tab)`
  max-width: 600px;
`;
