import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  currentMaterial: undefined,
  selectedChildMaterial: undefined,
  currentColorwayId: undefined,
  gridMaterials: [],
};

const materialsSlice = createSlice({
  name: 'materialsSlice',
  initialState,
  reducers: {
    setCurrentMaterial(state, { payload }) {
      state.currentMaterial = payload;
    },
    setGridMaterials(state, { payload }) {
      state.gridMaterials = payload;
    },
    setSelectedChildMaterial(state, { payload }) {
      state.selectedChildMaterial = payload;
    },
    setCurrentColorwayId(state, { payload }) {
      state.currentColorwayId = payload;
    },
    resetMaterialData(state) {
      state.gridMaterials = [];
      state.selectedChildMaterial = undefined;
      state.currentMaterial = undefined;
    },
  },
});

export const {
  setCurrentMaterial,
  setGridMaterials,
  setSelectedChildMaterial,
  setCurrentColorwayId,
  resetMaterialData,
} = materialsSlice.actions;
export default materialsSlice.reducer;
