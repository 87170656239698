import {
  AnalyticsEventName,
  ContactRepModalData,
  CustomEventName,
  isProjectTypeOther,
} from '@mb/lib';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import {
  buildAnalyticsParamsToPDP,
  mapProductToAnalyticsEcommerceItem,
} from './analytics';
import {
  getAnalyticsDataIfCollection,
  isSearchPage,
  triggerBoardsModal,
} from '../../../pages/ProductSearch/utils/helpers';
import {
  checkMultipleMaterialsAvailability,
  getMaterialsByKey,
} from '../api/materials';
import { ProductSearchMaterial } from '../types/material';
import { ProductDetails } from '../types/product';
import { IProject, IProjectTypes } from '../types/projects';

export const transformMaterialToAppFormat = (result: any) => ({
  productFinishType: result.product_finish_type,
  paintProductType: result.paint_product_type,
  isNew: result.is_new,
  isJustAdded: result.just_added,
  isCustom: false,
  isCollection: result.is_collection,
  isInStock: result.in_stock === '1',
  id: result.id,
  childrenCount: result.children_count,
  californiaProp65: result.california_prop_65,
  isInRealTimeStock: result.isInRealTimeStock,
  entityId: result.entity_id,
  ssStatePermission: result.ss_state_permission,
  manufacturer: result.manufacturer,
  manufacturerSku: result.manufacturer_sku ?? [],
  productType: result.product_type,
  qty: result.qty,
  residentialCommercial: result.residential_commercial,
  imageUrl: result.imageUrl,
  name: get(result, 'name', ''),
  sku: result.sku,
  brand: get(result, 'brand_name[0]', ''),
  url: result.url,
  sibling: null,
  siblings: result.siblings,
  categories: result.categories,
  ssFacetColorFilter: result.ss_facet_color_filter,
  indoorOutdoorUse: result.indoor_outdoor_use,
  priceSign: result.price_sign,
  color: result.color,
  thumbnailImageUrl: result.thumbnailImageUrl,
  itemType: result.item_type,
  configurations: result.configurations,
  blurhash: result.blurhash,
  components: result.components,
  colorwayId: result._colorway_id,
  ssItemType: result.ss_item_type,
  associatedFinishProducts: result.associated_finish_products,
  familyId: result.family_id,
  taxonomy: result.taxonomy || [],
  lightReflectanceValue: result.light_reflectance_value,
  originalMaterial: result,
  isPrimary: result.is_primary,
  sampleNote: result.sample_note,
  sampleType: result.sample_type,
  sizeNote: result.size_notes,
  additionalImages: result.additional_images || [],
  installImages: result.install_images || [],
  content: result.content_textile,
  attachments: result.attachments,
  isDigital: result.is_digital,
  isParent: String(result.entity_id) === String(result.family_id),
  isProduct: result.is_products,
});

export const checkStockAvailability = async (
  results: ProductSearchMaterial[] = [],
) => {
  try {
    const entityIds = results.map((result) => result.entityId);
    const materialsStatus = await checkMultipleMaterialsAvailability(entityIds);

    if (materialsStatus == null) {
      return results;
    }

    const updatedResponses = results.map((product) => {
      if (Array.isArray(materialsStatus)) {
        for (const response of materialsStatus) {
          if (String(response.product_id) === String(product.entityId)) {
            return {
              ...product,
              isInRealTimeStock: Number(response.qty) > 0,
            };
          }
        }
      }
      if (product.isInRealTimeStock == null) {
        return {
          ...product,
          isInRealTimeStock: false,
        };
      }
      return product;
    });
    return updatedResponses;
  } catch (e) {
    console.log(e);
    return [];
  }
};

const INFO_USER_LOCALSTORAGE = 'mage-cache-storage';

export const getUserAttributes = () => {
  return (
    JSON.parse(localStorage.getItem(INFO_USER_LOCALSTORAGE) || '{}')
      ?.customer || {}
  );
};

type FetchOptions = {
  timeout?: number;
} & RequestInit;

/**
 * Fetches data with a timeout.
 * @param resource - The resource URL.
 * @param options - The fetch options, including timeout.
 * @returns The fetch response.
 */
export async function fetchWithTimeout(
  resource: string,
  options: FetchOptions = {},
) {
  const { timeout = 1000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

export function getIsPaintProductType(material: ProductSearchMaterial) {
  if (material.paintProductType != null || material.ssItemType != null) {
    const paintType = material.paintProductType?.toLowerCase() || '';
    const ssItemType = material.ssItemType?.toLowerCase() || '';
    return (
      paintType.includes('product') ||
      paintType.includes('paint') ||
      ssItemType.includes('paint')
    );
  } else {
    return false;
  }
}

export const goToLogin = () =>
  (window.location.href =
    '/customer/account/login/' +
    `?callbackUrl=${encodeURIComponent(
      window.location.pathname + window.location.hash,
    )}`);

export const redirectGuestToLogin = () => {
  const userData = getUserAttributes();
  if (userData.signin_token == null) {
    goToLogin();
    return;
  }
};

export const getImageWithNewSize = (image = '', size = 300) => {
  const imageUrl = image?.split('?width')?.[0];
  if (imageUrl != null) {
    return `${imageUrl}?width=${size}`;
  }
  return image;
};

export const getActiveProductData = (
  product: ProductSearchMaterial,
  itemListId: string,
  itemListName: string,
): ProductDetails => {
  const taxonomy = product?.taxonomy?.[0];
  const type = taxonomy?.split(/>|\//)?.pop();
  const manufacturerSku = Array.isArray(product.manufacturerSku)
    ? product.manufacturerSku?.[0]
    : product.manufacturerSku;

  let finalListId = itemListId;
  let finalListTitle = itemListName;

  if (window.location.pathname?.includes('/curatedcollections/')) {
    const { id, title } = getAnalyticsDataIfCollection();
    finalListId = id;
    finalListTitle = title;
  }

  if (isSearchPage() && itemListId.startsWith('srp_a1')) {
    finalListId = 'srp_a1_recos_pdp';
    finalListTitle = 'srp a1 recos pdp';
  }

  const productDetails = {
    sku: manufacturerSku,
    categoryLabel: product.residentialCommercial?.join(', ') || '-',
    indoor: product.indoorOutdoorUse?.join(', '),
    nameLabel: product.name,
    type: type || '',
    price: product.priceSign || '-',
    brandLabel: product.manufacturer || product.brand,
    selectedBrand: {
      id: 1,
      label: product.brand || product.manufacturer,
    },
    entityId: product.entityId,
    brands: [{ id: 1, label: product.brand || product.manufacturer }],
    materialUrl: product.url?.split('?activeChild')?.[0],
    imageUrl: product.thumbnailImageUrl,
    sampleNote: product.sampleNote,
    sizeNote: product.sizeNote,
    labelText: product.isNew
      ? 'New'
      : product.isJustAdded
      ? 'Just Added'
      : undefined,
    additionalImages:
      product.isParent && !product.isProduct ? [] : product.additionalImages,
    installImages:
      product.isParent && !product.isProduct ? [] : product.installImages,
    attachments: product.attachments,
    originalMaterial: product,
  };

  const pdpTrackParams: string = buildAnalyticsParamsToPDP({
    product,
    itemListId: finalListId,
    itemListName: finalListTitle,
    index: 1,
  });

  productDetails.materialUrl += pdpTrackParams ? `?${pdpTrackParams}` : '';

  return productDetails;
};

export const getProductFinishes = async (
  product: ProductSearchMaterial | null,
) => {
  try {
    if (product == null) return;
    let componentsList = product?.components;
    const isParent = String(product.familyId) === String(product.entityId);

    if (product.familyId != null && !isParent) {
      const { results: selectedProductData } = await getMaterialsByKey([
        product.familyId as string,
      ]);
      componentsList = selectedProductData?.[0]?.components;
    }

    const pdpFinishes = await Promise.all(
      (componentsList ?? []).map(async (finishGroup) => {
        const sortedItems = orderBy(
          finishGroup.items || [],
          ['position'],
          ['asc'],
        );
        const itemsIds = sortedItems?.map((item) => String(item.entity_id));
        const { results } = await getMaterialsByKey(itemsIds);
        const formatedResults = results.map(transformMaterialToAppFormat);
        const stockResults = await checkStockAvailability(formatedResults);
        return {
          title: finishGroup.component_type,
          items: stockResults,
        };
      }),
    );
    return orderBy(pdpFinishes, ['title'], ['asc']);
  } catch (e) {
    console.log('getProductFinishes', e);
    return null;
  }
};

export const langsList = ['en', 'de', 'it', 'es', 'fr'];
const configLang =
  typeof ENVIRONMENT_REGION !== 'undefined' ? ENVIRONMENT_REGION : null;
export const isEU = configLang === 'EU';
export const getLocaleData = async () => {
  let locales = {};
  const storage = JSON.parse(
    localStorage.getItem('mage-cache-storage') as string,
  );
  const urlLang = document
    .querySelector('input[name=lang]:checked')
    ?.getAttribute('id')
    ?.replace('flag-', '');
  const storageLang = storage?.lang?.locale || localStorage.getItem('language');
  const localeRaw = urlLang || (storageLang as string);
  const locale = langsList.includes(localeRaw) ? localeRaw : 'en';

  if (!isEU) {
    return {
      currentLocale: 'en',
      messages: {},
    };
  }

  await fetch(`/media/translations/${locale}.json`)
    .then((response) => response.json())
    .then((data) => (locales = data))
    .catch((error) => {
      console.error(error);
    });

  return { currentLocale: locale, messages: locales };
};

export const getContactRepModalData = (
  product: ProductSearchMaterial,
  projectId: string,
  location: string,
): ContactRepModalData => {
  return {
    event_location: location,
    product_id: product?.entityId,
    product_name: product?.name,
    project_id: projectId,
    manufacturer_sku: product?.manufacturerSku?.[0],
    manufacturer: product?.manufacturer,
  };
};

export const handleAddToBoards = (
  product: ProductSearchMaterial,
  itemListId: string,
  itemListName: string,
  clickedProductGridIndex: number | undefined,
  location: string,
) => {
  const currentItemId = product?.entityId;
  const addToWishlistEventItem = mapProductToAnalyticsEcommerceItem({
    product,
    itemListId,
    itemListName,
    index:
      clickedProductGridIndex !== undefined
        ? clickedProductGridIndex + 1
        : undefined,
  });

  const currencies = {
    US: 'USD',
    EU: 'EUR',
    JP: 'JPY',
  };

  triggerBoardsModal({
    itemsData: [
      {
        id: String(currentItemId),
        type: 'material',
        associatedProductId: null,
      },
    ],
    onSuccessAnalyticsEvents: [
      { ecommerce: null },
      {
        event: 'add_to_wishlist',
        list_id: product?.isDigital ? 'digital_sampling' : itemListId,
        list_name: product?.isDigital ? 'Digital sampling' : itemListName,
        method: 'Single',
        type: 'Materials',
        name: null,
        action: null,
        experiment_name: null,
        experiment_version: null,
        event_location: location,
        ecommerce: {
          currency: currencies[configLang || 'US'],
          value: 0,
          items: [addToWishlistEventItem],
        },
      },
    ],
  });
};

export const handleTriggerProjectSelectModal = (
  projectId: string | number,
  analyticsEventLocation: string,
  onCurrentProjectSave: (project: IProject) => void,
  onProjectCreate: (project: IProject) => void,
  isDefaultView?: boolean,
) => {
  const defaultViewData = isDefaultView
    ? { defaultView: 'UPDATE_PROJECT' }
    : {};
  window.dispatchEvent(
    new CustomEvent(CustomEventName.OPEN_PROJECT_SELECTION_DIALOG, {
      detail: {
        ...defaultViewData,
        currentProjectId: projectId,
        analyticsEventLocation,
        onCurrentProjectSave: (project: IProject) => {
          onCurrentProjectSave?.(project);
        },
        onProjectCreate: (project: IProject) => {
          onProjectCreate?.(project);
        },
      },
    }),
  );
};

export const handleProjectDropdownClick = (
  project: IProject,
  projectTypes: IProjectTypes[],
  analyticsEventLocation: string,
  onCurrentProjectSave: (project: IProject) => void,
  onProjectCreate: (project: IProject) => void,
  onCurrentProjectSet: (id: string | number, project: IProject) => void,
) => {
  if (project) {
    const isOtherProjectType = isProjectTypeOther({
      projectTypes,
      projectType: String(project.project_type),
    });
    const isProjectLocationUnknown = !project.project_location;
    const isProjectPhaseUnknown = !project.phase_id;
    const isProjectDescriptionUnset = !project.project_details;

    if (
      isOtherProjectType ||
      isProjectLocationUnknown ||
      isProjectPhaseUnknown ||
      isProjectDescriptionUnset
    ) {
      handleTriggerProjectSelectModal(
        project.project_id,
        analyticsEventLocation,
        onCurrentProjectSave,
        onProjectCreate,
        true,
      );
    } else {
      onCurrentProjectSet?.(project.project_id, project);

      mitGTM.sendEvent({
        event: AnalyticsEventName.SET_PROJECT,
        event_location: analyticsEventLocation,
        action: 'Select',
      });
    }
  }
};
