import React from 'react';
import { AddToCartButton } from '../../containers/MiniPDP/MiniPdpContent.styled';

interface AddToBagButtonProps {
  buttonLabel?: string;
  onAddToCart: () => void;
  isAddToCartLoading?: boolean;
}

const AddToBagButton: React.FC<AddToBagButtonProps> = ({
  buttonLabel = 'Add to Cart',
  onAddToCart,
  isAddToCartLoading,
}) => {
  const handleAddToBag = () => {
    onAddToCart?.();
  };

  return (
    <AddToCartButton
      disabled={isAddToCartLoading}
      color="primary"
      size="large"
      variant="contained"
      onClick={handleAddToBag}
    >
      {buttonLabel}
    </AddToCartButton>
  );
};

export { AddToBagButton };
