import React, { useMemo } from 'react';
import {
  getAnalyticsItemCategoriesByTaxonomy,
  getAnalyticsItemType,
} from '@mb/lib';
import { Box } from '@mui/system';
import { IntlShape } from 'react-intl';
import * as Styled from './TooltipCard.styles';
import { formatImageSource } from '../../../../pages/ProductSearch/helpers';
import { definedTranslation } from '../../../../pages/ProductSearch/i18n';
import { ProductSearchMaterial } from '../../types/material';

export interface TooltipCardProps {
  buttonLabel?: string;
  material: ProductSearchMaterial;
  onCloseTooltip: () => void;
  intl: IntlShape;
}

const currencies = {
  US: 'USD',
  EU: 'EUR',
  JP: 'JPY',
};

const TooltipCard: React.FC<TooltipCardProps> = ({
  material,
  onCloseTooltip,
  intl,
}) => {
  const category = useMemo(() => {
    return material?.taxonomy?.[0]?.split('>')?.pop();
  }, [material]);

  const buttonLabel = intl?.formatMessage(
    definedTranslation.miniPDP.detailsButtonLabel,
  );

  const productInfo = useMemo(() => {
    const {
      residentialCommercial,
      indoorOutdoorUse,
      priceSign,
      content,
      manufacturerSku,
    } = material;

    const useList =
      residentialCommercial != null ? residentialCommercial.slice(0, 2) : [];
    const indoorOutdoorList = indoorOutdoorUse
      ? indoorOutdoorUse.slice(0, 2)
      : [];

    const data = [
      {
        label: intl?.formatMessage(definedTranslation.miniPDP.tooltipData.sku),
        value: manufacturerSku?.[0],
      },
      {
        label: intl?.formatMessage(
          definedTranslation.miniPDP.tooltipData.content,
        ),
        value: content?.[0],
      },
      {
        label: intl?.formatMessage(
          definedTranslation.miniPDP.tooltipData.price,
        ),
        value: priceSign?.[0],
      },
      {
        label: intl?.formatMessage(
          definedTranslation.card.listView.sectorLabel,
        ),
        value: useList.join(', '),
      },
      {
        label: intl?.formatMessage(
          definedTranslation.miniPDP.tooltipData.indoorOutdoor,
        ),
        value: indoorOutdoorList.join(', '),
      },
    ];

    return data?.filter((item) => item.value?.trim() != null);
  }, [material, intl]);

  const bannerImageUrl = formatImageSource(
    material?.thumbnailImageUrl,
    600,
    false,
    80,
  );

  const handleLearnMoreButtonClick = () => {
    window.splitFactory
      ?.client()
      ?.track('user', 'mb_qv_learn_more', undefined, {
        learnMoreLocation: 'variation-info',
      });

    dataLayer.push({
      event: 'minipdp_learn_more_interaction',
      event_location: 'Quick-View',
      ecommerce: {
        currency: currencies[ENVIRONMENT_REGION],
        value: 0,
        items: [
          {
            item_id: material.entityId,
            item_name: material?.associatedItem,
            item_brand: material.manufacturer,
            item_type: getAnalyticsItemType(material),
            ...getAnalyticsItemCategoriesByTaxonomy(material?.taxonomy),
            index: 1,
            price: 0,
            quantity: 1,
            item_list_id: 'quick_view',
            item_list_name: 'Quick View',
            item_variant: material.name,
          },
        ],
      },
    });
  };

  return (
    <Styled.CardWrapper>
      <Styled.CloseIcon
        color="secondary"
        iconName="cross"
        size="large"
        onClick={onCloseTooltip}
      />
      <Styled.ImageWrapper imageUrl={bannerImageUrl?.src} />
      <Styled.InfoWrapper>
        <Styled.Category variant="body">{category}</Styled.Category>
        <Styled.Title variant="body" fontWeight="700">
          {material.name}
        </Styled.Title>
        <Styled.Brand>{material.manufacturer}</Styled.Brand>
        <Box display="flex" flexDirection="column" mt="24px">
          {productInfo.map((item, index) => (
            <Styled.Line mb="14px" key={index}>
              <Styled.Label width="120px">{item.label}</Styled.Label>
              <Styled.Value>{item.value}</Styled.Value>
            </Styled.Line>
          ))}
        </Box>
        <Styled.LearnMoreButton
          LinkComponent={'a'}
          onClick={handleLearnMoreButtonClick}
          href={material.url}
          size="large"
          variant="contained"
          color="secondary"
        >
          {buttonLabel}
        </Styled.LearnMoreButton>
      </Styled.InfoWrapper>
    </Styled.CardWrapper>
  );
};

export default TooltipCard;
