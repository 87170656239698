import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { goToLogin, getUserAttributes } from '../../helpers';
import useAddToBag from '../../hooks/useAddToBag';
import useSwitchCurrentUserProject from '../../hooks/useSwitchCurrentUserProject';
import { materialsInQueueSelector } from '../../redux/selectors/commonSelector';
import {
  isProjectModalOpenSelector,
  projectModalEventLocationSelector,
} from '../../redux/selectors/projectsSelector';
import { setMaterialsInQueue } from '../../redux/sliceCreators/commonSlice';
import {
  setCreatedProject,
  setIsProjectModalOpen,
  setIsProjectArchived,
  setProjectModalEventLocation,
} from '../../redux/sliceCreators/projectsSlice';

const ProjectModalListener = () => {
  const isModalOpen = useSelector(isProjectModalOpenSelector);
  const projectModalEventLocation = useSelector(
    projectModalEventLocationSelector,
  );
  const materialsInQueue = useSelector(materialsInQueueSelector);
  const dispatch = useDispatch();
  const { addToBag } = useAddToBag();
  const { setCurrentUserProject } = useSwitchCurrentUserProject();
  const userData = getUserAttributes();

  useEffect(() => {
    window.addEventListener('res-create-project', handleSaveProject);
    window.addEventListener('close-mb-modal', handleCloseModal);
    window.addEventListener('onArchivationSuccess', handleArchiveProject);
    window.addEventListener('onUnarchivationSuccess', handleUnarchiveProject);
    return () => {
      window.removeEventListener('res-create-project', handleSaveProject);
      window.removeEventListener('close-mb-modal', handleCloseModal);
      window.removeEventListener('onArchivationSuccess', handleArchiveProject);
      window.removeEventListener(
        'onUnarchivationSuccess',
        handleUnarchiveProject,
      );
    };
  }, [materialsInQueue, isModalOpen]);

  useEffect(() => {
    if (isModalOpen) {
      if (userData.signin_token == null) {
        goToLogin();
        return;
      }
      window.dispatchEvent(
        new CustomEvent('open-create-project-modal', {
          detail: { eventLocation: projectModalEventLocation },
        }),
      );
    }
  }, [isModalOpen]);

  const handleArchiveProject = (e: CustomEvent) => {
    const { detail: projectId } = e;
    if (projectId != null) {
      dispatch(setIsProjectArchived({ id: projectId, isArchived: true }));
    }
  };

  const handleUnarchiveProject = (e: CustomEvent) => {
    const { detail: projectId } = e;
    if (projectId != null) {
      dispatch(setIsProjectArchived({ id: projectId, isArchived: false }));
    }
  };

  const handleSaveProject = async (e: CustomEvent) => {
    const { detail } = e;
    if (detail?.project_id != null) {
      dispatch(setCreatedProject(detail));
      dispatch(setIsProjectModalOpen(false));
      if (materialsInQueue?.length > 0) {
        await addToBag(materialsInQueue, detail?.project_id, detail);
        dispatch(setMaterialsInQueue([]));
        setCurrentUserProject(detail?.project_id, false, detail);
      } else {
        setCurrentUserProject(detail?.project_id, true, detail);
      }
    }
  };

  const handleCloseModal = () => {
    dispatch(setIsProjectModalOpen(false));
    dispatch(setProjectModalEventLocation(''));
  };

  return null;
};

export default ProjectModalListener;
