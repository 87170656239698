/**
 * Function to add two numbers
 * @param name Name of the file
 * @returns Name that is URL safe or empty string
 */
export const sanitizeFileName = (name: string): string => {
  if (name === undefined) {
    return '';
  }
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-|-$/g, '');
};
