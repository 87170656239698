import styled from '@emotion/styled';
import { Button } from 'components/Buttons/Button';
import { Typography } from 'components/Typography';
import { theme } from 'theme';

export const ImageWrapper = styled.div`
  display: flex;
  width: 136px;
  height: 136px;
  background-color: ${theme.palette.secondary.main};
  border-radius: 12px;
  overflow: hidden;
  img {
    width: 136px;
    height: 136px;
    object-fit: cover;
    filter: brightness(0.95);
    border-radius: 12px;
  }
`;

export const NameLabel = styled(Typography)`
  font-size: 10px;
  line-height: 1.6;
  color: #fff;
  padding: 2px 8px;
  border-radius: 50px;
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
`;

export const HoverContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: none;
  align-items: flex-end;
  justify-content: center;
  padding: 8px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
`;

export const CartButton = styled(Button)`
  font-size: 13px;
  &.Mui-disabled {
    background-color: ${theme.palette.secondary?.main};
    color: ${theme.palette.primary.main};
    opacity: 0.5;
  }
`;

export const CardWrapper = styled.div<{
  isActive?: boolean;
  isSelected?: boolean;
}>`
  display: flex;
  position: relative;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected &&
    `
        box-shadow: 0 0 0 2px #4D4E58;
        padding: 2px;
        border-radius: 12px;
    `}
  &:hover {
    ${HoverContainer} {
      display: flex;
    }
    ${NameLabel} {
      display: none;
    }
  }
  ${({ isActive }) =>
    isActive &&
    `
            ${HoverContainer} {
            display: flex;
        }
        ${NameLabel} {
            display: none;
        }
    `}
`;
