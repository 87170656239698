import styled from '@emotion/styled';
import { Tabs, Tab, Divider, Tooltip } from '@mui/material';
import { Button } from 'components/Buttons/Button';
import { IconButton } from 'components/Buttons/IconButton';
import MBDialog from 'components/Elements/MBDialog';
import Typography from 'components/Typography/Typography';
import { theme } from 'theme';

export const DialogInnerWrapper = styled.div`
  width: 100%;
  min-height: 700px;
`;

export const Dialog = styled(MBDialog)`
  margin-top: 32px;
  margin-bottom: 64px;
  z-index: 20 !important;
  & .MuiDialogContent-root {
    padding: 0 !important;
    margin-bottom: 0;
    overflow: visible;
  }
  & #close_modal_button {
    right: 12px;
    top: 12px;
    z-index: 30;
  }
  & > .MuiPaper-root {
    padding: 2em !important;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 21;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  #miniPDPMainContainer {
    height: auto;
    position: relative;
    overflow: hidden;
  }
`;

export const CategoriesTabs = styled(Tabs)`
  border-bottom: 1px solid ${theme.palette.grey[50]};
  margin-bottom: 1em;
  margin-top: 0.5em;
  & .MuiTab-root {
    padding-inline: 0;
    margin-right: 0.6em;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-transform: none;
    color: ${theme.palette.grey.A400};
    &.Mui-selected {
      color: ${theme.palette.primary.main} !important;
    }
  }
  & .MuiTabScrollButton-root.Mui-disabled {
    opacity: 0.25 !important;
  }
`;

export const FinishesList = styled.div`
  .virtuoso-grid-list {
    margin: 0;
    width: 100%;
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const CardWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  flex-grow: 0;
  width: 20%;
  padding: 0.8em 0.5em;
  & > *:first-of-type {
    width: 100%;
  }
`;

export const TabsSkeleton = styled.div`
  margin-top: 0.75em;
  padding-bottom: 1em;
  border-bottom: 1px solid ${theme.palette.grey[50]};
  margin-bottom: 1em;
`;

export const CategoryTab = styled(Tab)`
  max-width: 600px;
`;

export const MiniPdpColumnsWrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 600px;
  align-items: stretch;
`;
export const InfoOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 40px;
  position: relative;
  min-height: 600px;
`;
export const ProductTypeText = styled(Typography)`
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: ${theme.palette.grey.A400};
  &:hover {
    text-decoration: underline;
  }
`;
export const MainHeadline = styled(Typography)`
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  color: ${theme.palette.primary.main};
  padding-top: 8px;
  padding-bottom: 4px;
`;
export const MainSubtitle = styled(Typography)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${theme.palette.primary.main};
  &:hover {
    text-decoration: underline;
  }
`;
export const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid ${theme.palette.grey['50']};
  position: relative;
  z-index: 9;
`;
export const LearnMoreButton = styled(Button)`
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background: ${theme.palette.primary.main};
    box-shadow: none;
  }
`;
export const ContactRepButton = styled(IconButton)`
  width: 48px;
  height: 48px;
  margin-left: 12px;
  svg {
    width: 22px !important;
  }
`;

export const TooltipInfo = styled(Tooltip)`
  & ~ .MuiTooltip-popper {
    .MuiTooltip-tooltip {
      margin-top: 4px !important;
      background: ${theme.palette.primary.main};
      color: ${theme.palette.common.white};
      font-size: 12px;
      padding: 4px 10px;
      border-radius: 20px;
      text-align: center;
      min-height: 24px;
    }
  }
`;

export const ProjectSelector = styled.div`
  margin-left: auto;
  margin-right: 0;
  margin-top: 1em;
  max-width: 200px;
  ${theme.breakpoints.down('lg')} {
    max-width: 250px;
  }
  width: auto;
  height: 48px;
  position: relative;
  & > *:first-of-type {
    position: relative;
    width: auto;
  }
`;

export const ContactRepPortal = styled.div`
  position: relative;
`;

export const ConfigWrapper = styled.div<{ isInOneLine?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: ${({ isInOneLine }) => (isInOneLine ? '-56px' : '0')};
  margin-top: 20px;
  justify-content: flex-end;
  position: relative;
  z-index: 3;
  & > *:not(:last-of-type) {
    margin-right: 12px;
  }
`;

export const ConfigBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .MuiInput-root,
  .MuiOutlinedInput-root,
  .MuiFilledInput-root {
    height: 40px;
    max-width: 140px;
    div[role='button'] > .MuiListItemIcon-root {
      display: none;
    }
    & > span[font-size] {
      position: absolute;
      right: 0;
      top: 0;
    }
    fieldset {
      border: 0 !important;
    }
  }
  .MuiInputBase-root {
    .MuiSelect-select {
      padding: 4px 24px 4px 8px !important;
    }
  }
  .MuiSelect-icon.MuiSelect-icon.MuiSelect-icon {
    right: 0;
    top: 10px;
    width: 1.3em;
    color: ${theme.palette.grey.A400};
  }
  .selectLabel {
    color: ${theme.palette.grey.A400} !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const AddToCartButton = styled(Button)`
  margin-left: 12px;
`;

export const Line = styled(Divider)`
  border-top: 1px solid ${theme.palette.grey['50']};
  border-bottom-width: 0;
`;
