import { endpoints } from '../../config';
import { fetchWithTimeout } from '../../helpers';
import {
  analyticsPropsSelector,
  xRequestIdSelector,
} from '../../redux/selectors/commonSelector';
import store from '../../redux/store';

interface RequestProps {
  id: string | string[];
  type: 'view' | 'cart' | 'share' | 'favorite' | 'rate';
  value?: string;
  customRequestId?: string;
  customClass?: string;
  impressions?: string[];
}

type PayloadProps = Pick<RequestProps, 'id' | 'type' | 'value'> & {
  requestId?: string;
  class?: string;
  impressions?: string[];
};

export const sendAnalytics = async (
  data: RequestProps,
  timeout: number = 3000,
) => {
  try {
    const xRequestId = xRequestIdSelector(store.getState());
    const analyticsData = analyticsPropsSelector(store.getState());
    const {
      customRequestId,
      customClass,
      impressions,
      value = 'minipdp',
      ...rest
    } = data;
    const body: PayloadProps = {
      requestId: customRequestId || xRequestId,
      value,
      ...rest,
    };
    if (analyticsData?.analyticsClass != null || customClass != null) {
      body['class'] = customClass || analyticsData?.analyticsClass;
    }
    if (analyticsData?.impressions != null || (impressions || [])?.length > 0) {
      body['impressions'] = impressions || analyticsData?.impressions;
    }
    const response = fetchWithTimeout(endpoints.analytics, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: timeout,
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .catch((e) => {
        console.log('sendAnalytics', e);
      });
    return response;
  } catch (e) {
    console.log('Error sendAnalytics:', e);
  }
};
