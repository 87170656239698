import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  getAnalyticsItemCategoriesByTaxonomy,
  getAnalyticsItemType,
} from '@mb/lib';
import { AttachmentFile, FilesList } from 'components/components/FilesList';
import { IntlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './AttachmentsList.styled';
import {
  getCreateOrderPayload,
  createOrder,
  getCurrentProjectData,
} from '../../../../../../../../MaterialBank/UILibrary';
import { pushComponentAnchorLinkInteraction } from '../../helpers/analytics';
import useCurrentProject from '../../hooks/useCurrentProject';
import { definedTranslation } from '../../i18n';
import { projectsListSelector } from '../../redux/selectors/projectsSelector';
import { setIsProjectModalOpen } from '../../redux/sliceCreators/projectsSlice';
import { ProductSearchMaterial } from '../../types/material';

interface AttachmentsListProps {
  list: AttachmentFile[];
  item: null | ProductSearchMaterial;
  productUrl?: string;
  productSku?: string;
  intl: IntlShape;
}

const attachmentsLimit = 4;

const AttachmentsList: React.FC<AttachmentsListProps> = ({
  list,
  item,
  intl,
}) => {
  const [showAllAttachments, setShowAllAttachments] = useState(false);
  const [awaitingUrl, setAwaitingUrl] = useState<string | undefined>(undefined);
  const { currentProject } = useCurrentProject();
  const projects = useSelector(projectsListSelector);
  const dispatch = useDispatch();

  const labels = {
    files: intl?.formatMessage(definedTranslation.miniPDP.filesHeadline),
    more: intl?.formatMessage(definedTranslation.miniPDP.more),
    collapse: intl?.formatMessage(definedTranslation.miniPDP.collapse),
    download: intl?.formatMessage(definedTranslation.miniPDP.download),
  };

  // Callback after project creation
  const handleProjectCreated = () => {
    if (awaitingUrl) {
      window.open(awaitingUrl, '_blank')?.focus();
      setAwaitingUrl(undefined);
    }
  };

  useEffect(() => {
    window.addEventListener('onProjectCreated', handleProjectCreated);
    return () => {
      window.removeEventListener('onProjectCreated', handleProjectCreated);
    };
  }, [awaitingUrl]);

  useEffect(() => {
    return () => {
      setAwaitingUrl(undefined);
    };
  }, []);

  // Toggle attachments list
  const handleCollapse = () => {
    setShowAllAttachments((prev) => !prev);
  };

  const attachmentsList = showAllAttachments
    ? list
    : list?.slice(0, attachmentsLimit);

  const hasProjects = useMemo(() => {
    return projects?.length > 0 && Boolean(currentProject?.project_id);
  }, [currentProject, projects]);

  const handleItemPointerDown = useCallback(
    async (pdpUrl: string, fileId: string) => {
      if (!hasProjects) {
        dispatch(setIsProjectModalOpen(true));
        setAwaitingUrl(pdpUrl);
        return;
      }

      // Create order for download action
      const currentProjectData = getCurrentProjectData();
      if (item?.sku != null && currentProjectData != null && fileId != null) {
        const orderData = getCreateOrderPayload(
          item?.sku,
          currentProjectData,
          'Download',
          {
            attachmentId: parseInt(fileId),
          },
        );
        createOrder(orderData)
          .then((response) => {
            console.log('Order created successfully:', response);
          })
          .catch((error) => {
            console.error('Order creation failed:', error);
          });
      }

      pushComponentAnchorLinkInteraction([
        {
          ...getAnalyticsItemCategoriesByTaxonomy(item?.taxonomy || []),
          item_name: item?.name,
          item_brand: item?.originalMaterial?.manufacturer,
          item_type: getAnalyticsItemType(item?.originalMaterial),
          item_id: item?.entityId,
          item_variant: item?.name,
          item_list_id: 'digital_sampling',
          item_list_name: 'digital sampling',
          index: 1,
          quantity: 1,
          price: 0,
        },
      ]);
    },
    [item, currentProject, projects, dispatch],
  );

  return (
    <Styled.FilesWrapper>
      {list?.length > 4 && (
        <Styled.DataWrapper
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Styled.InfoHeadline>{labels.files}</Styled.InfoHeadline>
          <Styled.CollapseButton
            color="secondary"
            variant="contained"
            size="medium"
            onClick={handleCollapse}
          >
            {labels.collapse}
          </Styled.CollapseButton>
        </Styled.DataWrapper>
      )}
      <FilesList
        item={item}
        list={attachmentsList}
        onPointerDown={handleItemPointerDown}
        isRedirectClickAllowed={hasProjects}
        downloadButtonLabel={labels.download}
      />
    </Styled.FilesWrapper>
  );
};

export { AttachmentsList };
