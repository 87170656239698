import React, { useState, useEffect, Fragment } from 'react';
import { App } from '@mb/App';
import { useTrackSplitTimeOnPage } from '@mb/lib/splitio';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { ProjectModalListener } from './components/ProjectsModal';
import { DataWrapper } from './containers/DataWrapper';
import { getLocaleData, isEU } from './helpers';
import store from './redux/store';
import { ClickedMaterialProps } from './types/details';

const defaultProps = {
  currentMaterialId: undefined,
  clickedProductGridIndex: undefined,
  clickedProductRequestId: undefined,
  isProduct: false,
  isMaterial: false,
  learnMoreNewTab: false,
  dataLayerAnalytics: undefined,
};

const MiniPDPContainer = () => {
  useTrackSplitTimeOnPage({ pageName: 'Mini PDP' });
  const [clickedMaterialData, setClickedMaterialData] =
    useState<ClickedMaterialProps>(defaultProps);
  const [langData, setLangData] = useState({
    currentLocale: 'en',
    messages: {},
  });

  useEffect(() => {
    if (isEU) {
      handleLangData();
    }
  }, []);

  const handleLangData = async () => {
    const data = await getLocaleData();
    setLangData(data);
  };

  useEffect(() => {
    window.addEventListener('initMiniPDP', handleInitMiniPDP);
    return () => {
      window.removeEventListener('initMiniPDP', handleInitMiniPDP);
    };
  }, []);

  const handleInitMiniPDP = (e: CustomEvent) => {
    if (e.detail?.id != null) {
      setClickedMaterialData({
        currentMaterialId: e.detail.id,
        clickedProductGridIndex: e.detail.clickedProductGridIndex,
        clickedProductRequestId: e.detail.clickedProductRequestId,
        isProduct: e.detail?.isProduct,
        isMaterial: e.detail?.isMaterial,
        learnMoreNewTab: e.detail?.learnMoreNewTab,
        clickedProductAnalyticsClass: e.detail?.clickedProductAnalyticsClass,
        clickedProductAnalyticsImpressions:
          e.detail?.clickedProductAnalyticsImpressions,
        dataLayerAnalytics: e.detail?.dataLayerAnalytics,
      });
    }
  };

  const handleClose = () => {
    setClickedMaterialData(defaultProps);
    // fix for page scroll on modal close
    setTimeout(() => {
      document?.getElementById('html-body')?.removeAttribute('style');
    }, 400);
  };

  if (!clickedMaterialData?.currentMaterialId) {
    return <Fragment />;
  }

  const intlMessages = isEU ? langData?.messages : {};
  const intlLocale = isEU ? langData?.currentLocale : 'en';

  return (
    <App>
      <IntlProvider
        messages={intlMessages}
        locale={intlLocale}
        defaultLocale="en"
      >
        {/* @ts-expect-error // TODO: fix types */}
        <Provider store={store}>
          <DataWrapper
            onModalClose={handleClose}
            clickedMaterialData={clickedMaterialData}
          />
          <ProjectModalListener />
        </Provider>
      </IntlProvider>
    </App>
  );
};

export default MiniPDPContainer;
