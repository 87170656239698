import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  MutableRefObject,
} from 'react';
import { AnalyticsEventLocation } from '@mb/lib';
import { encodeFilterValue } from '@mb/lib/search';
import { Box } from '@mui/system';
import { AttachmentFile } from 'components/components/FilesList';
import xor from 'lodash/xor';
import { useSelector, useDispatch } from 'react-redux';
import * as Styled from './MiniPdpContent.styled';
import { formatImageSource } from '../../../../pages/ProductSearch/helpers';
import { sendAnalytics } from '../../api/events';
import { AttachmentsList } from '../../components/AttachmentsList';
import DescriptionContainer from '../../components/DescriptionContainer/DescriptionContainer';
import FinishesGroup from '../../components/FinishesGroup/FinishesGroup';
import ImagesContainer from '../../components/ImagesContainer/ImagesContainer';
import { ProjectMenuDropdown } from '../../components/ProjectMenuDropdown/ProjectMenuDropdown';
import { getActiveProductData, getProductFinishes } from '../../helpers';
import {
  handleMiniPdpDetailsClickDataLayer,
  handleMiniPdpProductClickAnalytics,
  handleProductViewedEvent,
  pushSearchAnalyticsDataLayer,
} from '../../helpers/analytics';
import useAddToBagWithValidations from '../../hooks/useAddToBagWithValidations';
import { definedTranslation } from '../../i18n';
import {
  currentMaterialSelector,
  gridMaterialsSelector,
} from '../../redux/selectors/materialsSelector';
import {
  setGridMaterials,
  setSelectedChildMaterial,
} from '../../redux/sliceCreators/materialsSlice';
import { DataModalProps } from '../../types';
import { FinishesGroupData, ProductSearchMaterial } from '../../types/material';

const MiniPdpContent = React.forwardRef<HTMLDivElement, DataModalProps>(
  (
    {
      onPdpClose,
      itemListId = 'mini_pdp',
      itemListName = 'Mini PDP',
      clickedProductGridIndex,
      cartItemsIds = [],
      intl,
      structuredProjects = [],
      shouldShowContactButton,
      userLang,
      currentProjectData,
      trackingData = {},
      learnMoreNewTab = false,
      wasModalTriggered,
      onModalTriggered,
      onContactRepClick,
      dataLayerAnalytics,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const finishes = useSelector(gridMaterialsSelector);
    const [activeProduct, setActiveProduct] = useState<any>(null);
    const [activeGroups, setActiveGroups] = useState<number[]>([]);
    const [attachments, setAttachments] = useState<AttachmentFile[]>([]);
    const portalTargetRef = useRef<HTMLDivElement | null>(null);
    const localRef = useRef<HTMLDivElement>(null);
    const resolvedRef =
      (ref as MutableRefObject<HTMLDivElement | null>) || localRef;
    const currentMaterial = useSelector(currentMaterialSelector);
    const { handleAddToBag: addToBag, loadingIds } =
      useAddToBagWithValidations();

    const translation = {
      detailsButtonLabel: intl?.formatMessage(
        definedTranslation.miniPDP.detailsButtonLabel,
      ),
      createProject: intl?.formatMessage(
        definedTranslation.miniPDP.createProjectButtonLabel,
      ),
      contactRepLabel: intl?.formatMessage(
        definedTranslation.miniPDP.contactRepButtonLabel,
      ),
    };

    const shouldShowPDP = activeProduct != null;

    useEffect(() => {
      initProduct(currentMaterial);
    }, [currentMaterial]);

    const initProduct = (currentMaterial: ProductSearchMaterial) => {
      if (currentMaterial != null) {
        getFinishes(currentMaterial);
        handleSetActiveProduct(currentMaterial);
      }
    };

    const sendViewItemAnalytics = (product: ProductSearchMaterial) => {
      // Analytics
      pushSearchAnalyticsDataLayer(
        [product],
        '',
        'mini_pdp',
        'Mini PDP',
        'view_item',
        'Mini-PDP',
      );
    };

    const getFinishes = async (product: ProductSearchMaterial | null) => {
      const finishes = await getProductFinishes(product);
      dispatch(setGridMaterials(finishes));
    };

    const handleSetActiveProduct = (
      product: ProductSearchMaterial | null,
    ): boolean => {
      setActiveProduct(null);

      if (product == null) return false;

      sendViewItemAnalytics(product);
      handleProductViewedEvent(product);
      const productDetails = getActiveProductData(
        product,
        dataLayerAnalytics?.listId ?? itemListId,
        dataLayerAnalytics?.listName ?? itemListName,
      );
      setActiveProduct(productDetails);
      if (productDetails?.attachments != null) {
        // Hide attachments URL and redirect to pdp
        const newFiles = productDetails?.attachments?.map((item) => ({
          ...item,
          file_url: item.url,
          url: `${productDetails.materialUrl}&fileId=${item.id}`,
        }));
        setAttachments(newFiles);
      }

      return false;
    };

    const handleProductClick = async (material: ProductSearchMaterial) => {
      await sendAnalytics(
        {
          id: [String(material?.entityId)],
          type: 'view',
          value: 'minipdp',
        },
        1200,
      );
      handleMiniPdpProductClickAnalytics(material);
      sendViewItemAnalytics(material);
      dispatch(setSelectedChildMaterial(material));
    };

    const handleDetailsClick = async (e: React.SyntheticEvent) => {
      e.preventDefault();
      await sendAnalytics(
        {
          id: [String(activeProduct?.entityId)],
          type: 'view',
          value: 'minipdp',
        },
        1200,
      );
      handleMiniPdpDetailsClickDataLayer(
        {
          ...activeProduct?.originalMaterial,
          url: activeProduct?.materialUrl,
        },
        learnMoreNewTab,
      );
    };

    const handleAddedToCart = (
      product: ProductSearchMaterial,
      inFinish: boolean = false,
    ) => {
      addToBag({
        product,
        shouldTriggerRecModal: wasModalTriggered,
        trackingData: {},
        onAddToCartClick: undefined,
        qvLocation: 'variations',
        atcLocation: 'QV',
        isFinish: inFinish,
        relatedProductData: {
          id: currentMaterial?.entityId,
          title: `${currentMaterial?.manufacturer} ${currentMaterial?.name}`,
        },
      });
      onModalTriggered?.(true);
    };

    const handleCollapseClick = (index: number) => {
      setActiveGroups((prev) => xor([index], prev));
    };

    const handleCloseTooltip = () => {
      dispatch(setSelectedChildMaterial(undefined));
    };

    const bannerImageUrl = formatImageSource(
      activeProduct?.imageUrl,
      1200,
      false,
      80,
    );
    const prefix = userLang === 'en' ? '' : `/${userLang}`;
    const productCategory = activeProduct?.originalMaterial?.taxonomy?.[0];
    const brandLink = `${prefix}/search?q=#/filter:manufacturer:${encodeFilterValue(
      activeProduct?.brandLabel,
    )}#/page:1`;
    const categoryLink = `${prefix}/search?q=#/filter:taxonomy:${encodeFilterValue(
      productCategory,
    )}#/page:1`;
    const hasAttachments =
      Array.isArray(attachments) && attachments?.length > 0;
    const isDigitalProduct = activeProduct?.originalMaterial?.isDigital;

    const handleCategoryClick = (e: React.SyntheticEvent) => {
      e.preventDefault();
      window.location.href = categoryLink;
      onPdpClose?.(undefined, 'redirect');
    };

    const handleBrandClick = (e: React.SyntheticEvent) => {
      e.preventDefault();
      window.location.href = brandLink;
      onPdpClose?.(undefined, 'redirect');
    };

    if (!shouldShowPDP) {
      return <Fragment />;
    }

    return (
      <Styled.MiniPdpColumnsWrapper>
        <ImagesContainer
          activeProduct={activeProduct}
          mainImage={bannerImageUrl?.src}
          images={activeProduct?.installImages}
          itemListId={itemListId}
          itemListName={itemListName}
          clickedProductGridIndex={clickedProductGridIndex}
          onCloseTooltip={handleCloseTooltip}
          intl={intl}
          modalType={AnalyticsEventLocation.MINI_PDP}
          dataLayerAnalytics={dataLayerAnalytics}
          ref={resolvedRef}
        />
        <Styled.InfoOuterWrapper>
          <Box display="flex" flexDirection="column">
            <Styled.ProductTypeText
              component="a"
              href={categoryLink}
              onClick={handleCategoryClick}
            >
              {activeProduct.type}
            </Styled.ProductTypeText>
            <Styled.MainHeadline>{activeProduct.nameLabel}</Styled.MainHeadline>
            <Styled.MainSubtitle
              component="a"
              href={brandLink}
              onClick={handleBrandClick}
            >
              {activeProduct.brandLabel}
            </Styled.MainSubtitle>
          </Box>
          <Styled.ActionsWrapper>
            <Styled.ContactRepPortal ref={portalTargetRef} />
            <Styled.LearnMoreButton
              LinkComponent={'a'}
              href={activeProduct?.materialUrl}
              size="large"
              variant="contained"
              color="primary"
              onClick={handleDetailsClick}
            >
              {translation.detailsButtonLabel}
            </Styled.LearnMoreButton>
            {shouldShowContactButton && (
              <Styled.TooltipInfo
                PopperProps={{
                  disablePortal: true,
                }}
                title={translation.contactRepLabel}
              >
                <Styled.ContactRepButton
                  size="large"
                  iconName="mail"
                  color="secondary"
                  onClick={() => onContactRepClick?.(portalTargetRef)}
                />
              </Styled.TooltipInfo>
            )}
            <Styled.ProjectSelector>
              <ProjectMenuDropdown
                entityId={activeProduct?.entityId}
                createProjectButtonLabel={translation.createProject}
              />
            </Styled.ProjectSelector>
          </Styled.ActionsWrapper>
          <DescriptionContainer intl={intl} activeProduct={activeProduct} />
          {hasAttachments && isDigitalProduct ? (
            <AttachmentsList
              item={activeProduct?.originalMaterial}
              list={attachments}
              productUrl={activeProduct?.materialUrl}
              productSku={activeProduct?.sku}
              intl={intl}
            />
          ) : (
            <Styled.Line />
          )}
          <Box width="100%" display="flex" flexDirection="column">
            {finishes.map((item: FinishesGroupData, groupIndex: number) => {
              return (
                <FinishesGroup
                  key={groupIndex}
                  item={item}
                  collapseLimit={finishes?.length === 1 ? 8 : 4}
                  groupIndex={groupIndex}
                  activeGroups={activeGroups}
                  onActiveGroupChange={handleCollapseClick}
                  parentRef={resolvedRef?.current || undefined}
                  intl={intl}
                  userLang={userLang}
                  clickedProductGridIndex={clickedProductGridIndex}
                  activeProduct={activeProduct}
                  projects={structuredProjects}
                  currentProjectData={currentProjectData}
                  cartItemsIds={cartItemsIds}
                  wasModalTriggered={wasModalTriggered}
                  onProductClick={handleProductClick}
                  onAddToCart={handleAddedToCart}
                  trackingData={trackingData}
                  loadingCartIds={loadingIds}
                />
              );
            })}
          </Box>
        </Styled.InfoOuterWrapper>
      </Styled.MiniPdpColumnsWrapper>
    );
  },
);

export { MiniPdpContent };
