import styled from '@emotion/styled';
import Typography from 'components/Typography/Typography';
import { theme, pxToEm } from 'theme';

export const ContainerWrapper = styled.div<{
  minHeight?: number;
  marginBottom?: number;
}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 0}px;`}
    min-height: ${({ minHeight }) => `${minHeight || 0}px;`}
`;

export const RecommendContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const ImagesGridWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const ImagesContent = styled.div<{ isAligned?: boolean }>`
  display: flex;
  gap: 12px;
  padding: 2px 0;
`;

export const ImagesCarousel = styled.div`
  overflow: hidden;
  width: 100%;
`;

export const Title = styled(Typography)`
  cursor: pointer;
  padding: 1em 0;

  ${theme.breakpoints.down('md')} {
    font-size: ${pxToEm(18)};
    line-height: ${pxToEm(24)};
  }
`;
