import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { IconButton } from 'components/Buttons';
import { EmblaCarouselType } from 'embla-carousel';
import * as Styled from './SliderArrows.styled';

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined,
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

export enum ArrowDirection {
  RIGHT = 'right',
  LEFT = 'left',
}

type ArrowProps = {
  direction: ArrowDirection;
  onClick: () => void;
  show: boolean;
};

export const Arrow: React.FC<ArrowProps> = ({ direction, onClick, show }) => {
  return (
    <Styled.Arrow direction={direction} show={show}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <IconButton
          onClick={onClick}
          size="small"
          color="secondary"
          iconName={
            ArrowDirection.RIGHT === direction ? 'arrow-right' : 'arrow-left'
          }
        />
      </Box>
    </Styled.Arrow>
  );
};
