import styled from '@emotion/styled';
import { pxToEm, theme, palette } from 'theme';
import { ArrowDirection } from './SliderArrows';

type DirectionProps = { direction: ArrowDirection; show?: boolean };

export const Arrow = styled.div<DirectionProps>`
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  right: ${({ direction }) =>
    direction === ArrowDirection.RIGHT ? pxToEm(-24) : 'auto'};
  left: ${({ direction }) =>
    direction === ArrowDirection.LEFT ? pxToEm(-24) : 'auto'};
  top: 0;
  z-index: 2;
  transition: all 0.1s linear;
  pointer-events: none;

  ${theme.breakpoints.down('md')} {
    display: none;
  }

  & button {
    width: ${pxToEm(48)};
    height: ${pxToEm(48)};
    font-size: 1em;
    background: ${palette.common.white} !important;
    color: ${palette.primary.main} !important;
    pointer-events: auto;
    box-shadow: ${theme.shadows[3]};

    &:focus,
    &:active {
      color: ${palette.primary.main} !important;
      background: ${palette.secondary?.main} !important;
    }
  }
`;
