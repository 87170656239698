import React from 'react';
import { AnalyticsEventLocation } from '@mb/lib';
import { SplitEventData, mergeSplitProperties } from '@mb/lib/splitio';
import { CardInfoLevel } from 'components/Elements/GridTypeSelector/GridTypeSelector.types';
import { IntlShape } from 'react-intl';
import { ResultCard } from './ResultCard';
import { handleMiniPdpVariantClickDataLayer } from '../../helpers/analytics';
import { ProductSearchMaterial } from '../../types/material';
import { ProductDetails } from '../../types/product';
import { IProject, StructuredProject } from '../../types/projects';

export interface CardWrapperProps {
  cardIndex: number;
  groupFinishes: ProductSearchMaterial[];
  clickedProductGridIndex?: number;
  clickedProductRequestId?: string;
  activeProduct: ProductDetails;
  projects: StructuredProject[];
  currentProjectData: StructuredProject;
  intl: IntlShape;
  userLang: 'en' | 'fr' | 'de' | 'it' | 'es';
  cartItemsIds: string[];
  wasModalTriggered: boolean;
  currentMaterialId?: string;
  onProductClick: (material: ProductSearchMaterial) => void;
  onCreateProjectClick?: (params: any) => void;
  onProjectClick?: (id: string, options: any) => void;
  onAddToCart: (material?: ProductSearchMaterial, isFinish?: boolean) => void;
  onProjectCreate?: (project: IProject) => void;
  onProjectSelect?: (projectId: number) => void;
  isColorways?: boolean;
  trackingData?: {
    splitio?: SplitEventData;
    gtm?: unknown;
  };
  loadingCartIds?: string[];
}

export const CardWrapper: React.FC<CardWrapperProps> = ({
  cardIndex,
  groupFinishes = [],
  clickedProductGridIndex,
  activeProduct,
  projects,
  currentProjectData,
  intl,
  userLang,
  cartItemsIds,
  wasModalTriggered,
  onProductClick,
  onAddToCart,
  currentMaterialId,
  isColorways,
  trackingData = {},
  loadingCartIds,
}) => {
  let material = groupFinishes?.[cardIndex];

  if (material == null) {
    return null;
  }

  material = {
    ...material,
    gridIndex: clickedProductGridIndex,
    associatedItem: activeProduct?.nameLabel,
  };

  const handleProductClick = (material: ProductSearchMaterial) => {
    handleMiniPdpVariantClickDataLayer(material);
    onProductClick?.(material);
  };

  return (
    <ResultCard
      loadingCartIds={loadingCartIds}
      isMiniPDPFinish={!isColorways}
      isColorway={isColorways}
      material={material}
      index={cardIndex}
      imageSize={516}
      infoMode={CardInfoLevel.LESS_INFO}
      projects={projects}
      selectedProject={currentProjectData}
      onProductClick={handleProductClick}
      cartItemsIds={cartItemsIds}
      relatedProductInfo={{
        title: `${activeProduct?.brandLabel}: ${activeProduct?.nameLabel}`,
        id: activeProduct?.entityId,
      }}
      shouldTriggerRecModal={!wasModalTriggered}
      onAddToCart={onAddToCart}
      itemListId="mini_pdp"
      itemListName="Mini PDP"
      analyticsEventLocation={AnalyticsEventLocation.MINI_PDP}
      intl={intl}
      userLang={userLang}
      isOutlined={currentMaterialId === material.entityId}
      additionalSplitIoProps={mergeSplitProperties(
        {
          qvLocation: 'variations',
          'ATC-location': 'QV',
        },
        trackingData.splitio?.properties,
      )}
    />
  );
};
