import React from 'react';
import { createRoot } from 'react-dom/client';

import { ThemeContainer } from 'ui-widgets';
import MiniPDPContainer from '../../../components/MiniPDPContainer/MiniPDPContainer';

const MiniPDPContainerWrapper = () => {
  return (
    <ThemeContainer>
      <MiniPDPContainer />
    </ThemeContainer>
  );
};

const container = document.getElementById('miniPDPContainerWrapper');
const root = createRoot(container!);
root.render(<MiniPDPContainerWrapper />);
