import React, { useEffect, useMemo, Fragment, useRef, useState } from 'react';
import {
  AnalyticsEventLocation,
  getUserAttributes,
  triggerContactRepModal,
} from '@mb/lib';
import { getContactRepAllowedGroups } from '@mb/lib/customer';
import { getUserLang } from '@mb/lib/i18n';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useIsMobileDevice } from 'utils';
import { getRecommendationsModalSettings } from '../../../../pages/ProductSearch/helpers';
import { sendAnalytics } from '../../api/events';
import { getMaterialsByKey, getRecommendStrip } from '../../api/materials';
import { getUserProjectTypes, getUserProjects } from '../../api/projects';
import { BackButton } from '../../components/BackButton/BackButton';
import { ErrorModal } from '../../components/ErrorModal';
import { RecommendBlock } from '../../components/RecommendBlock';
import { Snackbar } from '../../components/Snackbar';
import {
  getContactRepModalData,
  transformMaterialToAppFormat,
} from '../../helpers';
import useCurrentProject from '../../hooks/useCurrentProject';
import { cartItemsSelector } from '../../redux/selectors/commonSelector';
import {
  currentColorwayIdSelector,
  currentMaterialSelector,
  selectedChildMaterialSelector,
} from '../../redux/selectors/materialsSelector';
import { projectsListSelector } from '../../redux/selectors/projectsSelector';
import {
  removeCartItemFromQueue,
  setAnalyticsProps,
  setXRequestId,
} from '../../redux/sliceCreators/commonSlice';
import {
  resetMaterialData,
  setCurrentMaterial,
  setGridMaterials,
} from '../../redux/sliceCreators/materialsSlice';
import { setCurrentProjectId } from '../../redux/sliceCreators/projectsSlice';
import { setStripData } from '../../redux/sliceCreators/recommendSlice';
import { ClickedMaterialProps } from '../../types/details';
import { ProductSearchMaterial } from '../../types/material';
import { StructuredProject } from '../../types/projects';
import { RecommendResponseObject } from '../../types/recommendStrip';
import { MiniPdpContent } from '../MiniPDP';
import { QuickViewContent } from '../MiniPDP';
import { ModalWrapper, Dialog } from '../MiniPDP/MiniPdpContent.styled';
import { DialogInnerWrapper } from '../MiniPDP/MiniPdpContent.styled';

interface DataWrapperProps {
  onModalClose: () => void;
  clickedMaterialData: ClickedMaterialProps;
}

interface AnalyticsDataProps {
  requestId?: string;
  impressios?: string[];
  class?: string;
}

const activeBagTime = 2500;
const customerData = getUserAttributes();
const recommendSettings = getRecommendationsModalSettings();
const allowedGroups = recommendSettings?.['allowed-groups'];
const isAllowedGroup = allowedGroups?.includes(String(customerData?.groupId));

const DataWrapper: React.FC<DataWrapperProps> = ({
  clickedMaterialData,
  onModalClose,
}) => {
  const {
    currentMaterialId,
    clickedProductGridIndex,
    clickedProductRequestId,
    learnMoreNewTab,
  } = clickedMaterialData;
  const cartItems = useSelector(cartItemsSelector);
  const projects = useSelector(projectsListSelector);
  const currentMaterial = useSelector(currentMaterialSelector);
  const selectedMaterial = useSelector(selectedChildMaterialSelector);
  const isLargeScreen = useIsMobileDevice('lg');
  const { currentProject } = useCurrentProject();
  const dispatch = useDispatch();
  const intl = useIntl();
  const userLang = getUserLang();
  const scrollerRef = useRef(null);
  const [recPayload, setRecPayload] = useState<
    RecommendResponseObject[] | undefined
  >(undefined);
  const [wasModalTriggered, setWasModalTriggered] = useState(false);
  const [parentMaterial, setParentMaterial] = useState<
    ProductSearchMaterial | undefined
  >(undefined);
  const [selectedParentVariant, setSelectedParentVariant] = useState<
    ProductSearchMaterial | undefined
  >(undefined);
  const [parentAnalyticsData, setParentAnalyticsData] = useState<
    AnalyticsDataProps | undefined
  >(undefined);
  const activeRecommendationId =
    parentMaterial != null ? String(currentMaterial?.entityId) : undefined;
  const selectedColorwayId = useSelector(currentColorwayIdSelector);

  const shouldShowRecommend = isAllowedGroup;

  useEffect(() => {
    // update current project from Storage on each modal init
    dispatch(setCurrentProjectId(getUserAttributes()?.current_project));
    dispatch(getUserProjects());
    dispatch(getUserProjectTypes());
  }, []);

  useEffect(() => {
    if (clickedMaterialData?.currentMaterialId != null) {
      handleGetMaterial(
        clickedMaterialData?.currentMaterialId,
        clickedMaterialData.isRecommendation,
      );
    }
  }, [clickedMaterialData]);

  useEffect(() => {
    if (clickedMaterialData?.clickedProductRequestId != null) {
      dispatch(setXRequestId(clickedMaterialData?.clickedProductRequestId));
    }
    dispatch(
      setAnalyticsProps({
        analyticsClass: clickedMaterialData?.clickedProductAnalyticsClass,
        impressions: clickedMaterialData?.clickedProductAnalyticsImpressions,
      }),
    );
  }, [clickedMaterialData]);

  // If something was added to Cart, show isAdded icon for X secconds,
  // and then remove icon
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const oldCartItems = cartItems?.filter(
        (item: any) => now - item.time >= activeBagTime,
      );
      if (oldCartItems?.length > 0) {
        dispatch(removeCartItemFromQueue(oldCartItems));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [cartItems]);

  const shouldShowContactButton = useMemo(() => {
    const allowedGroups = getContactRepAllowedGroups();

    return Boolean(
      !isLargeScreen &&
        currentProject?.project_id &&
        allowedGroups
          .map((i: any) => Number(i))
          .indexOf(customerData?.groupId) !== -1,
    );
  }, [isLargeScreen, currentProject]);

  const cartItemsIds = useMemo(() => {
    return cartItems?.map((item: ProductSearchMaterial) => item.entityId) || [];
  }, [cartItems]);

  const structuredProjects = useMemo(() => {
    return [...projects].map((project) => ({
      id: project.project_id,
      label: project.project_title,
    }));
  }, [projects]);

  const currentProjectData = useMemo(
    () => ({
      id: currentProject?.project_id || 0,
      label: currentProject?.project_title ?? 'Select project..',
    }),
    [currentProject],
  ) as StructuredProject;

  const getProduct = (
    currentMaterial: ProductSearchMaterial,
    selectedMaterial: ProductSearchMaterial,
    selectedColorwayId: string | undefined,
  ): ProductSearchMaterial => {
    if (selectedMaterial) {
      return selectedMaterial;
    }

    if (
      currentMaterial?.ssItemType !== 'finish_product' &&
      selectedColorwayId
    ) {
      return { ...currentMaterial, entityId: selectedColorwayId };
    }

    return currentMaterial;
  };

  const contactRepModalData = useMemo(() => {
    const product = getProduct(
      currentMaterial,
      selectedMaterial,
      selectedColorwayId,
    );
    return getContactRepModalData(
      product,
      currentProject?.project_id as string,
      AnalyticsEventLocation.MINI_PDP,
    );
  }, [currentMaterial, currentProject, selectedColorwayId, selectedMaterial]);

  const handleBackClick = async () => {
    if (parentMaterial != undefined) {
      dispatch(setXRequestId(parentAnalyticsData?.requestId));
      dispatch(
        setAnalyticsProps({
          analyticsClass: parentAnalyticsData?.class,
          impressions: parentAnalyticsData?.impressios,
        }),
      );
      dispatch(setCurrentMaterial(undefined));
      dispatch(setGridMaterials([]));
      // Add delay, to reset data visually
      await new Promise((resolve) => setTimeout(resolve, 250));
      if (selectedParentVariant != null) {
        dispatch(setCurrentMaterial(selectedParentVariant));
      } else {
        dispatch(setCurrentMaterial(parentMaterial));
      }
      setParentMaterial(undefined);
      setParentAnalyticsData(undefined);
    }
  };

  const scrollToTop = () => {
    if (scrollerRef.current) {
      // @ts-expect-error // TODO: fix types
      scrollerRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleRecommendationClick = async (material: ProductSearchMaterial) => {
    scrollToTop();
    const product = {
      ...material,
      isRecommendation: true,
    };
    if (parentMaterial == undefined) {
      setParentMaterial(currentMaterial);
    }
    if (clickedMaterialData != null) {
      const {
        clickedProductRequestId,
        clickedProductAnalyticsImpressions,
        clickedProductAnalyticsClass,
      } = clickedMaterialData;
      setParentAnalyticsData({
        requestId: clickedProductRequestId,
        impressios: clickedProductAnalyticsImpressions,
        class: clickedProductAnalyticsClass,
      });
      dispatch(setXRequestId(product?.originRequestId));
      dispatch(
        setAnalyticsProps({
          analyticsClass: product.analyticsClass,
          impressions: product?.siblingIds,
        }),
      );
    }
    dispatch(setGridMaterials([]));
    dispatch(setCurrentMaterial(undefined));
    await sendAnalytics(
      {
        id: [String(product?.entityId)],
        type: 'view',
        value: 'minipdp',
        customRequestId: product?.originRequestId,
        customClass: product.analyticsClass || 'recommend',
        impressions: product?.siblingIds || [],
      },
      1200,
    );
    // Add delay
    await new Promise((resolve) => setTimeout(resolve, 250));
    dispatch(setCurrentMaterial(product));
  };

  const handleGetMaterial = async (id: string, isRecommendation = false) => {
    if (id != null) {
      const { results, recommendationStrips } = await getMaterialsByKey(
        [String(id)],
        true,
      );
      if (results?.[0] != null) {
        const formatedResults = results.map(transformMaterialToAppFormat);
        const product = formatedResults?.[0];
        dispatch(setCurrentMaterial(product));
      }
      if (
        Array.isArray(recommendationStrips) &&
        recommendationStrips?.length > 0 &&
        !isRecommendation &&
        shouldShowRecommend
      ) {
        setRecPayload(recommendationStrips);
        handleStripData(recommendationStrips);
      }
    }
  };

  const handleVariationClick = (material: ProductSearchMaterial) => {
    if (Array.isArray(recPayload)) {
      const newPayload = recPayload?.map((item: RecommendResponseObject) => ({
        ...item,
        payload: {
          ...item?.payload,
          itemIds: [String(material?.entityId)],
        },
      }));
      handleStripData(newPayload);
    }
    if (parentMaterial == undefined) {
      setSelectedParentVariant(material);
    }
  };

  const handleStripData = async (data: RecommendResponseObject[]) => {
    const recommendItems = await Promise.all(
      (data || []).map(async (item: RecommendResponseObject) => {
        const results = await getRecommendStrip({
          urlPath: item.urlPath,
          payload: item.payload,
        });
        return {
          label: item.label,
          materials: results,
          payload: item.payload,
          loaded: true,
        };
      }),
    );
    const filteredList = recommendItems?.filter(
      (obj) => obj.materials?.length > 0,
    );
    if (filteredList?.length > 0) {
      dispatch(setStripData(filteredList));
    }
  };

  const handleContactRep = (
    ref: React.MutableRefObject<HTMLDivElement | null>,
  ) => {
    triggerContactRepModal({
      modalPlacement: 'bottom',
      portalTarget: ref.current as HTMLElement,
      data: contactRepModalData,
    });
    window.splitFactory
      ?.client()
      ?.track('user', 'mb_qv_contact_rep', undefined, {});
  };

  const handleModalTriggered = (value: boolean) => {
    setWasModalTriggered(value);
  };

  const handleClosePDP = (
    _?: Record<string, any>,
    reason?: string | undefined,
  ) => {
    if (reason === undefined) {
      window.splitFactory
        ?.client()
        ?.track('user', 'mb_qv_close', undefined, {});
    }
    sessionStorage.removeItem('a1LocationTracking');
    dispatch(resetMaterialData());
    setWasModalTriggered(false);
    setParentMaterial(undefined);
    dispatch(setStripData([]));
    onModalClose?.();
    window.dispatchEvent(new CustomEvent('handleMiniPdpCloseEvent'));
  };

  const isProduct =
    currentMaterial?.isProduct ||
    currentMaterial?.productFinishType?.includes('Product');

  return (
    <ModalWrapper id="miniPdpModalWrapper" ref={scrollerRef}>
      <Dialog
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        disableRestoreFocus
        title=""
        id="miniPDPMainContainer"
        showCloseIcon
        open
        onClose={handleClosePDP}
        dialogWidth={1376}
      >
        <DialogInnerWrapper>
          {parentMaterial != null && (
            <BackButton
              name={parentMaterial?.name}
              onButtonClick={handleBackClick}
            />
          )}
          {currentMaterial != null && (
            <Fragment>
              {isProduct ? (
                <MiniPdpContent
                  onPdpClose={handleClosePDP}
                  clickedProductGridIndex={clickedProductGridIndex}
                  clickedProductRequestId={clickedProductRequestId}
                  cartItemsIds={cartItemsIds}
                  intl={intl}
                  structuredProjects={structuredProjects}
                  shouldShowContactButton={shouldShowContactButton}
                  userLang={userLang}
                  currentProjectData={currentProjectData}
                  learnMoreNewTab={learnMoreNewTab}
                  wasModalTriggered={wasModalTriggered}
                  onModalTriggered={handleModalTriggered}
                  ref={scrollerRef}
                  onContactRepClick={handleContactRep}
                  dataLayerAnalytics={clickedMaterialData.dataLayerAnalytics}
                />
              ) : (
                <QuickViewContent
                  onPdpClose={handleClosePDP}
                  clickedProductGridIndex={clickedProductGridIndex}
                  clickedProductRequestId={clickedProductRequestId}
                  cartItemsIds={cartItemsIds}
                  intl={intl}
                  structuredProjects={structuredProjects}
                  shouldShowContactButton={shouldShowContactButton}
                  userLang={userLang}
                  currentProjectData={currentProjectData}
                  learnMoreNewTab={learnMoreNewTab}
                  wasModalTriggered={wasModalTriggered}
                  onModalTriggered={handleModalTriggered}
                  // @ts-expect-error // TODO: fix types
                  ref={scrollerRef}
                  onContactRepClick={handleContactRep}
                  dataLayerAnalytics={clickedMaterialData.dataLayerAnalytics}
                  onVariationClick={handleVariationClick}
                />
              )}
            </Fragment>
          )}
        </DialogInnerWrapper>
        {shouldShowRecommend && (
          <RecommendBlock
            activeItemId={activeRecommendationId}
            onRecommendationClick={handleRecommendationClick}
          />
        )}
      </Dialog>
      <Snackbar />
      <ErrorModal dialogWidth={480} />
    </ModalWrapper>
  );
};

export { DataWrapper };
