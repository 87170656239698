import React, { FC, useCallback, useState } from 'react';
import { downloadZip } from 'client-zip';
import styles from './DownloadAllComponent.module.scss';
import { sanitizeFileName } from '../../helpers/sanitizeFileName';
import { ProductDetails } from '../../types/product';
import { ReactComponent as DownloadIcon } from '../DownloadButtonComponent/download-icon.svg';

interface DownloadAllComponentProps {
  files: string[];
  activeProduct: ProductDetails;
}

export const DownloadAllComponent: FC<DownloadAllComponentProps> = ({
  files,
  activeProduct,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const composedFileName = `${sanitizeFileName(
    activeProduct.nameLabel,
  )}--${sanitizeFileName(activeProduct.originalMaterial.title)}`;
  const handleDownload = useCallback(async () => {
    setIsLoading(true);
    const filesToZip = await Promise.all(
      files.map(async (file) => {
        const response = await fetch(`${file}?format=jpg`);

        if (!response.ok) {
          throw new Error(
            `Failed to fetch ${file}: ${response.status} ${response.statusText}`,
          );
        }

        const blob = await response.blob();
        return { name: file.split('/').pop(), input: blob };
      }),
    );

    const blob = await downloadZip(filesToZip).blob();

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${composedFileName || 'Product-images'}.zip`;
    link.click();
    link.remove();
    setIsLoading(false);
  }, [files]);

  return (
    <button
      type="button"
      onClick={handleDownload}
      className={styles.downloadAllComponent}
    >
      <DownloadIcon /> {isLoading && <span>Loading...</span>}
    </button>
  );
};
