import { createDeepEqualSelector } from './';

export const materialsSelector = (state: any) => state.materials;

export const currentMaterialSelector = createDeepEqualSelector(
  materialsSelector,
  (materials) => materials.currentMaterial,
);

export const selectedChildMaterialSelector = createDeepEqualSelector(
  materialsSelector,
  (materials) => materials.selectedChildMaterial,
);

export const currentColorwayIdSelector = createDeepEqualSelector(
  materialsSelector,
  (materials) => materials.currentColorwayId,
);

export const gridMaterialsSelector = createDeepEqualSelector(
  materialsSelector,
  (materials) => materials.gridMaterials || [],
);
