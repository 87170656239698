import React from 'react';
import * as Styled from './MiniCard.styled';
import { formatImageSource } from '../../../pages/ProductSearch/helpers';
import { MiniCardProps } from '../RecommendStripUI.types';

const MiniCard: React.FC<MiniCardProps> = ({
  product,
  cardButtonsLabels,
  onAddToCart,
  onCardClick,
  isAddToCartLoading,
  isSelected,
}) => {
  const labels = {
    addToCart: cardButtonsLabels?.addToCart || 'Add to Cart',
    learnMore: cardButtonsLabels?.learnMore || 'Learn More',
    sampleFinishes: cardButtonsLabels?.sampleFinishes || 'Sample Finishes',
  };

  const isProduct =
    product.isProduct || product?.productFinishType?.includes('Product');
  const isDigital = product.isDigital;

  const image = formatImageSource(product.thumbnailImageUrl, 150, false, 80);
  const name = product?.taxonomyL2?.[0] || product.name;
  const buttonLabel = isProduct
    ? labels.sampleFinishes
    : isDigital
    ? labels.learnMore
    : labels.addToCart;

  const handleActionButtonClick = (e: React.SyntheticEvent) => {
    if (product.isProduct || product.isDigital) {
      onCardClick?.(product);
    } else {
      e.stopPropagation();
      onAddToCart?.(product);
    }
  };

  return (
    <Styled.CardWrapper
      isActive={isAddToCartLoading}
      onClick={() => onCardClick?.(product)}
      isSelected={isSelected}
    >
      <Styled.ImageWrapper>
        {image && image.src ? (
          <img
            src={image.src}
            loading="lazy"
            title={name}
            alt={name}
            width="136"
            height="136"
          />
        ) : (
          <img
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            width="136"
            height="136"
            alt={name}
          />
        )}
      </Styled.ImageWrapper>
      <Styled.NameLabel variant="body">{name}</Styled.NameLabel>
      <Styled.HoverContainer>
        <Styled.CartButton
          fullWidth
          variant="contained"
          color="secondary"
          disabled={isAddToCartLoading}
          onClick={handleActionButtonClick}
        >
          {buttonLabel}
        </Styled.CartButton>
      </Styled.HoverContainer>
    </Styled.CardWrapper>
  );
};

export { MiniCard };
