import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { CustomIcon } from 'components/CustomIcon';
import TypographyComponent from 'components/Typography/Typography';
import {
  ConfigWrapper,
  ConfigBox,
} from '../../containers/MiniPDP/MiniPdpContent.styled';
import { SelectboxConfig, CurrentConfigData } from '../../types/quickView';

interface FinishesConfigProps {
  configData: SelectboxConfig[];
  currentConfig: CurrentConfigData[];
  onFilterChange: (value: string, attribute: string) => void;
}

const FinishesConfig: React.FC<FinishesConfigProps> = ({
  configData,
  currentConfig,
  onFilterChange,
}) => {
  // Do not show Color Select, since we are showing ALL colorways in grid
  const configDataToRender = configData.filter(
    (conf) => conf.attribute !== 'color',
  );
  if (configDataToRender?.length === 0) {
    return null;
  }
  return (
    <ConfigWrapper isInOneLine={configDataToRender?.length === 1}>
      {configDataToRender?.map((item) => {
        const value =
          currentConfig.find((config) => config.attribute === item.attribute)
            ?.label || item?.variants?.[0]?.label;
        const itemLabel = item.label?.replace('Available ', '');
        return (
          <ConfigBox key={item.attribute}>
            <TypographyComponent variant="body" className="selectLabel">
              {itemLabel}:
            </TypographyComponent>
            <Select
              value={value}
              IconComponent={(props) => (
                <CustomIcon name="arrow-down" {...props} />
              )}
              MenuProps={{
                disablePortal: true,
              }}
              onChange={(e) =>
                onFilterChange?.(e.target?.value, item.attribute)
              }
            >
              {item?.variants?.map((config, index) => (
                <MenuItem key={config.label + index} value={config.label}>
                  {config.label}
                </MenuItem>
              ))}
            </Select>
          </ConfigBox>
        );
      })}
    </ConfigWrapper>
  );
};

export { FinishesConfig };
