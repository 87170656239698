import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import * as Styled from './ImageSlider.styled';
import { formatImageSource } from '../../../../pages/ProductSearch/helpers';
import { ProductDetails } from '../../types/product';
import { DownloadAllComponent } from '../DownloadAllComponent';
import {
  usePrevNextButtons,
  PrevButton,
  NextButton,
} from '../SliderArrows/SliderArrows';

interface ImageSliderProps {
  imageList: string[];
  currentImage: string;
  onImageSelect: (item: string, isLast: boolean) => void;
  activeProduct: ProductDetails;
}

const ImageSlider: React.FC<ImageSliderProps> = ({
  imageList,
  currentImage,
  onImageSelect,
  activeProduct,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 5,
  });

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const shouldShowButtons = imageList?.length > 5;

  return (
    <Styled.ImagesGridWrapper>
      {shouldShowButtons && (
        <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
      )}
      <Styled.ImagesCarousel ref={emblaRef}>
        <Styled.ImagesContent isAligned={imageList?.length <= 5}>
          {imageList?.map((item, index) => {
            const imageSrc = formatImageSource(item, 180, false, 100);
            const currentImageRaw = currentImage.split('?')?.[0];
            const isLast = imageList.length - 1 === index;

            return (
              <Styled.Image
                isSelected={currentImageRaw === item}
                key={item + index}
                src={imageSrc?.src}
                onClick={() => onImageSelect?.(imageSrc?.src, isLast)}
              />
            );
          })}
          {imageList?.length > 1 ? (
            <DownloadAllComponent
              files={imageList}
              activeProduct={activeProduct}
            />
          ) : null}
        </Styled.ImagesContent>
      </Styled.ImagesCarousel>
      {shouldShowButtons && (
        <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
      )}
    </Styled.ImagesGridWrapper>
  );
};

export default ImageSlider;
