// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';
import Typography from 'components/Typography/Typography';

export const DescirptionColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
`;

export const DescriptionGrid = styled.div`
  width: 100%;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  ${DescirptionColumn} {
    width: 33.33%;
    &:not(:first-of-type) {
      padding-left: 20px;
      border-left: 1px solid ${({ theme }) => theme.palette.grey['50']};
    }
  }
`;

export const DescriptionLabel = styled(Typography)`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.grey['A400']};
`;
export const DescriptionValue = styled(Typography)<{ isNote?: boolean }>`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.main};
  padding-top: 6px;
  line-break: ${({ isNote }) => (isNote ? 'normal' : 'anywhere')};
`;
