import React, { Fragment } from 'react';
import { Box } from '@mui/system';
import { IntlShape } from 'react-intl';
import * as Styled from './DescriptionContainer.styled';
import { definedTranslation } from '../../i18n';
import { ProductDetails } from '../../types/product';

interface DescriptionContainerProps {
  activeProduct: ProductDetails;
  intl: IntlShape;
  showSize?: boolean;
}

const DescriptionContainer: React.FC<DescriptionContainerProps> = ({
  activeProduct,
  intl,
  showSize = true,
}) => {
  const translation = {
    infoUsageLabel: intl?.formatMessage(
      definedTranslation.miniPDP.infoUsageLabel,
    ),
    infoSkuLabel: intl?.formatMessage(definedTranslation.miniPDP.infoSkuLabel),
    indoorOutdoorLabel: intl?.formatMessage(
      definedTranslation.miniPDP.indoorOutdoorLabel,
    ),
    sizeLabel: intl?.formatMessage(definedTranslation.miniPDP.sizeLabel),
    sampleNoteLabel: intl?.formatMessage(definedTranslation.miniPDP.noteLabel),
  };

  return (
    <Fragment>
      <Styled.DescriptionGrid>
        <Styled.DescirptionColumn>
          <Styled.DescriptionLabel component="span">
            {translation.infoUsageLabel}
          </Styled.DescriptionLabel>
          <Styled.DescriptionValue component="p">
            {activeProduct.categoryLabel}
          </Styled.DescriptionValue>
        </Styled.DescirptionColumn>
        <Styled.DescirptionColumn>
          <Styled.DescriptionLabel component="span">
            {translation.indoorOutdoorLabel}
          </Styled.DescriptionLabel>
          <Styled.DescriptionValue component="p">
            {activeProduct.indoor}
          </Styled.DescriptionValue>
        </Styled.DescirptionColumn>
        <Styled.DescirptionColumn>
          <Styled.DescriptionLabel component="span">
            {translation.infoSkuLabel}
          </Styled.DescriptionLabel>
          <Styled.DescriptionValue component="p">
            {activeProduct.sku}
          </Styled.DescriptionValue>
        </Styled.DescirptionColumn>
      </Styled.DescriptionGrid>
      {showSize && activeProduct.sizeNote != null && (
        <Box display="flex" marginBottom="20px">
          <Styled.DescirptionColumn>
            <Styled.DescriptionLabel component="span">
              {translation.sizeLabel}
            </Styled.DescriptionLabel>
            <Styled.DescriptionValue component="p">
              {activeProduct.sizeNote}
            </Styled.DescriptionValue>
          </Styled.DescirptionColumn>
        </Box>
      )}
      {activeProduct?.sampleNote != null && (
        <Box display="flex" marginBottom="20px">
          <Styled.DescirptionColumn>
            <Styled.DescriptionLabel component="span">
              {translation.sampleNoteLabel}
            </Styled.DescriptionLabel>
            <Styled.DescriptionValue component="p" isNote>
              {activeProduct.sampleNote}
            </Styled.DescriptionValue>
          </Styled.DescirptionColumn>
        </Box>
      )}
    </Fragment>
  );
};

export default DescriptionContainer;
