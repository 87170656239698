import styled from '@emotion/styled';
import { Button } from 'components/Buttons/Button';
import { IconButton } from 'components/Buttons/IconButton';
import { theme } from 'theme';

export const FullScreenButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 3;
  background: #fff;
  opacity: 0;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 16px;
  height: 520px;
  max-height: 520px;
  &:hover {
    button {
      opacity: 1;
    }
    a {
      opacity: 1;
    }
  }
`;

export const ActiveImage = styled.img`
  max-width: 100%;
  max-height: 520px;
  width: auto;
  border-radius: 20px;
  filter: brightness(0.95);
  ${theme.breakpoints.up(1280)} {
    max-width: 520px;
  }
`;

export const ImageOuterWrapper = styled.div<{ isFullScreen?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  position: relative;
  flex-wrap: wrap;
  & > div:first-of-type {
    width: 100%;
  }
  ${({ isFullScreen }) =>
    isFullScreen
      ? `
        position: fixed;
        top: 0;
        left: 0;
        background: #fff;
        width: 100%;
        height: 100%;
        z-index: 99999;
        ${ImageWrapper} {
            height: calc(100vh - 250px);
            max-height: calc(100vh - 250px);
            max-width: none;
        }
        ${ActiveImage} {
            max-height: calc(100vh - 250px);
            max-width: none;
        }
    `
      : `
        min-height: 100%;
    `}
`;

export const AddToBoardButton = styled(Button)`
  position: relative;
  top: 0;
  right: 40px;
  z-index: 2;
  align-self: end;
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background: ${theme.palette.primary.main};
    box-shadow: none;
  }
`;

export const ImagesColumn = styled.div<{ isFullScreen?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const CloseFullScreenButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 4;
`;

export const StickyBlock = styled.div`
  display: block;
  padding-top: 0;
  width: 46vw;
  ${theme.breakpoints.up(1280)} {
    width: 656px;
  }
`;
