import { createSlice } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';
import { getUserAttributes } from '../../helpers';

const userData = getUserAttributes();

export const initialState = {
  projectsList: [],
  projectTypes: [],
  phases: [],
  currentProjectId: userData?.current_project,
  isProjectModalOpen: false,
  isProjectsLoaded: false,
  projectModalEventLocation: '',
};

const projectsSlice = createSlice({
  name: 'projectsSlice',
  initialState,
  reducers: {
    setProjects(state, { payload }) {
      state.projectsList = payload;
    },
    setProjectTypes(state, { payload }) {
      state.projectTypes = payload;
    },
    setCreatedProject(state, { payload }) {
      const projectsList = uniqBy(
        [payload, ...state.projectsList],
        'project_id',
      );
      // @ts-expect-error // TODO: fix types
      state.projectsList = projectsList;
    },
    setCurrentProjectId(state, { payload }) {
      state.currentProjectId = payload;
    },
    setIsProjectModalOpen(state, { payload }) {
      state.isProjectModalOpen = payload;
    },
    setProjectPhases(state, { payload }) {
      state.phases = payload;
    },
    setIsProjectsLoaded(state, { payload }) {
      state.isProjectsLoaded = payload;
    },
    setIsProjectArchived(state, { payload }) {
      // @ts-expect-error // TODO: fix types
      state.projectsList = state.projectsList.map((item) => {
        // @ts-expect-error // TODO: fix types
        if (item.project_id !== payload?.id) {
          return item;
        }
        return {
          // @ts-expect-error // TODO: fix types
          ...item,
          // @ts-expect-error // TODO: fix types
          is_archived: payload?.isArchived ?? item.is_archived,
        };
      });
    },
    setProjectModalEventLocation(state, { payload }) {
      state.projectModalEventLocation = payload;
    },
  },
});

export const {
  setProjects,
  setProjectTypes,
  setCreatedProject,
  setCurrentProjectId,
  setIsProjectModalOpen,
  setProjectPhases,
  setIsProjectArchived,
  setIsProjectsLoaded,
  setProjectModalEventLocation,
} = projectsSlice.actions;
export default projectsSlice.reducer;
