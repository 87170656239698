import styled from '@emotion/styled';
import Typography from 'components/Typography/Typography';
import { CustomIcon } from 'ui-widgets';

export const GoBackButton = styled(Typography)`
  display: flex;
  position: absolute;
  left: 16px;
  top: 20px;
  z-index: 30;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  .text {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
`;

export const BackIcon = styled(CustomIcon)`
  width: 24px;
`;
