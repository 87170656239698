import axios from 'axios';
import indexOf from 'lodash/indexOf';
import sortBy from 'lodash/sortBy';
import { extraFieldsList } from '../../../pages/ProductSearch/hooks';
import { siteId, endpoints, rawApiURL } from '../config';
import { getUserAttributes, transformMaterialToAppFormat } from '../helpers';

const localExtraFields = [
  ...extraFieldsList,
  'size_notes',
  'content_textile',
  'attachments',
  'is_digital',
];

// @ts-expect-error // TODO: fix types
export const checkMultipleMaterialsAvailability = async (materialsIds) => {
  try {
    if (materialsIds?.length === 0) {
      return null;
    }

    const bodyFormData = new FormData();
    bodyFormData.append('items', materialsIds);
    bodyFormData.append('origin', 'search');

    const response = await axios.post(
      '/catalog/product/inventory',
      bodyFormData,
    );
    const { data } = response;
    return data?.data;
  } catch (error) {
    console.error('Error while checking stock >>> ', error.message);

    return null;
  }
};

export const getMaterialsByKey = async (
  keyList: (string | number)[],
  isParent = false,
  customRequestId: string | undefined = undefined,
  customAnalyticsClass: string | undefined = undefined,
  customImpressions: string[] | undefined = undefined,
) => {
  try {
    const customer = getUserAttributes();
    const storage = JSON.parse(
      localStorage.getItem('mage-cache-storage') as string,
    );
    const storageLang = storage?.lang?.locale || 'en';
    const configLang =
      typeof ENVIRONMENT_REGION !== 'undefined' ? ENVIRONMENT_REGION : null;
    const body: any = {
      siteId: siteId,
      'key.entity_id': keyList,
      fieldsOnly: true,
      extraFields: localExtraFields,
    };

    if (customer?.email != null) {
      body['customerEmail'] = customer?.email;
    }
    if (customer?.customer_id != null) {
      body['customerId'] = Number(customer?.customer_id) || null;
    }

    if (configLang != null) {
      body['lang'] = `${storageLang}-${ENVIRONMENT_REGION}`;
    }
    if (isParent) {
      body['recommendationStripLocations'] = ['minipdp'];
    }
    let xRequestId = undefined;

    const response = await fetch(endpoints.byKey, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then((res) => {
      xRequestId = res?.headers?.get('x-request-id');
      return res.json();
    });

    const { results = [], recommendationStrips, ...rest } = response;
    const sortedResults = sortBy(results, function (obj) {
      return indexOf(keyList, String(obj.entity_id));
    });
    return {
      results: sortedResults,
      recommendationStrips: recommendationStrips?.recommendationStrips || [],
      xRequestId,
      ...rest,
    };
  } catch (e) {
    console.log('Error getMaterialsByKey:', e);
    return null;
  }
};

export interface A1Props {
  urlPath: string;
  payload: any;
}

export const getRecommendStrip = async (props: A1Props) => {
  try {
    let xRequestId: string | null = null;
    const response = await fetch(`${rawApiURL}${props.urlPath}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...props.payload,
        extraFields: localExtraFields,
        referrerUrl: window.location.host + window.location.pathname,
      }),
    }).then((res) => {
      xRequestId = res.headers.get('x-request-id');
      return res.json();
    });

    const results = response?.[0]?.results;
    // Add specific field for Split analytics
    const isComplementary =
      props.payload?.recommenderMode?.includes('complementary');
    const qvLocation = isComplementary ? 'complementary' : 'sampled';
    const analyticsPostfix = isComplementary ? 'compprods' : 'alsosampled';
    // @ts-expect-error // TODO: fix types
    const ids = results?.map((item) => String(item.entity_id));
    const analyticsClass = 'recommend';

    if (Array.isArray(results)) {
      const materials = results.map((item) => {
        const newItem = transformMaterialToAppFormat(item);
        return {
          ...newItem,
          originRequestId: xRequestId,
          qvLocation,
          analyticsPostfix,
          siblingIds: ids,
          analyticsClass,
        };
      });
      return materials;
    }
    return [];
  } catch (e) {
    console.log('getRecommendStripA1 miniPDP', e);
    return [];
  }
};
